import React from "react";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import Stats from "./Stats";
import { Container, Row, Col } from "react-bootstrap";
import MajorSearch from "./MajorSearch";
import Carouselset from "./common/Carouselset";
import Twitter from "./common/Twitter";
// import Pop from "../pops.png";

function Homepage(props) {
  return (
    <div>
      <NavBar />
      <MajorSearch />
      <br />
      <Container fluid className="d-flex my-5">
        <Row>
          <Col style={{ minWidth: "15rem" }} className="col-3 my-3">
            {/* <img className="d-block w-100" src={Pop} /> */}
            <Stats />
          </Col>
          <Col style={{ minWidth: "30rem" }} className="col-6">
            <Carouselset />
          </Col>
          <Col
            style={{ minWidth: "15rem" }}
            className="col-3 align-items-center"
          >
            <Twitter />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Homepage;
