import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
// import { Container } from "react-bootstrap";
import RTableLigand from "./RtableLigand";
import IPaddress from "../../services/IPaddress";

const IP = IPaddress();

function LigandTable(props) {
  const { id } = useParams();
  const [ligand, setLigand] = useState([]);
  const [type, setType] = useState([]);
  const [organism, setOrganism] = useState([]);
  const [tissue, setTissue] = useState([]);
  const [cell, setCell] = useState([]);

  useEffect(() => {
    async function getLigand() {
      const results = await axios.get(`${IP}/chembl/${id}`);
      setLigand(results.data.data);

      setType(
        [...new Set([...results.data.data.map((c) => c.standard_type)])].sort()
      );

      setOrganism([
        ...new Set(
          [...results.data.data.map((c) => c.assay_organism)].filter(
            (c) => c !== "None"
          )
        ),
      ]);

      setTissue([
        ...new Set(
          [...results.data.data.map((c) => c.assay_tissue)].filter(
            (c) => c !== "None"
          )
        ),
      ]);

      setCell([
        ...new Set(
          [...results.data.data.map((c) => c.assay_cell)].filter(
            (c) => c !== "None"
          )
        ),
      ]);
    }
    getLigand();
  }, [id]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Chembl ID",
        accessor: "chembl_id", // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row }) => (
          <Link to={`/chembl/${row.original.chembl_id}`}>
            {row.original.chembl_id}
          </Link>
        ),
      },
      {
        Header: "Name",
        accessor: "molecule_name",
        disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "standard_type",
        disableSortBy: true,
      },
      {
        Header: "Relation",
        accessor: "standard_relation",
        disableSortBy: true,
      },
      {
        Header: "Value",
        accessor: "standard_value",
        minWidth: 100,
      },
      {
        Header: "Unit",
        accessor: "unit",
        disableSortBy: true,
      },
      {
        Header: "pChembl",
        accessor: "pchembl_value",
        minWidth: 120,
      },
      {
        Header: "SMILES",
        accessor: "smiles",
        disableSortBy: true,
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Assay ID",
        accessor: "assay_id",
        disableSortBy: true,
      },
      {
        Header: "Assay organism",
        accessor: "assay_organism",
        disableSortBy: true,
        minWidth: 150,
      },
      {
        Header: "Assay tissue",
        accessor: "assay_tissue",
        disableSortBy: true,
        minWidth: 150,
      },
      {
        Header: "Assay cell",
        accessor: "assay_cell",
        disableSortBy: true,
        minWidth: 150,
      },
      {
        Header: "Assay description",
        accessor: "assay_description",
        disableSortBy: true,
        maxWidth: 600,
        minWidth: 600,
        style: { whiteSpace: "unset" },
      },
    ],
    []
  );

  return (
    <div style={{ width: "95vw" }}>
      <RTableLigand
        columns={columns}
        data={ligand}
        typeOptions={type}
        organismOptions={organism}
        tissueOptions={tissue}
        cellOptions={cell}
      ></RTableLigand>
    </div>
  );
}

export default LigandTable;
