import React from "react";
import { Nav, Navbar } from "react-bootstrap";
// import SearchBar from "./SearchBar";

function NavBar(props) {
  return (
    <div>
      <Navbar
        className="fw-bold py-0"
        collapseOnSelect
        expand="lg"
        style={{
          backgroundColor: "#263878",
        }}
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link className="nav-links mx-2" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="nav-links mx-2" href="/gpcr">
              GPCRs
            </Nav.Link>
            <Nav.Link className="nav-links mx-2" href="/drug">
              Ligand Index
            </Nav.Link>
            <Nav.Link className="nav-links mx-2" href="/biopred">
              EC50/IC50 Prediction
            </Nav.Link>
            <Nav.Link className="nav-links mx-2" href="/affinity">
              Affinity Prediction
            </Nav.Link>
            <Nav.Link className="nav-links mx-2" href="/help">
              Documentation
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
