import React from "react";
// import { Helmet } from "react-helmet";
// import ScriptTag from "react-script-tag";

import { TwitterTimelineEmbed } from "react-twitter-embed";

function Twitter(props) {
  return (
    <div className="">
      <TwitterTimelineEmbed
        onLoad={function noRefCheck() {}}
        noScrollbar
        options={{
          height: 350,
        }}
        screenName="SRouuuuu"
        sourceType="profile"
      />
    </div>
  );
}

export default Twitter;

//  twitter id: 1379867699409477634
