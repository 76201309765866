import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import SearchBar from "./common/SearchBar";
import logo from "../7TMkb_name.png";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function MajorSearch(props) {
  const [gpcrlist, setGpcrlist] = useState([]);
  const [druglist, setDruglist] = useState([]);
  const [chemblist, setChemblist] = useState([]);

  useEffect(() => {
    async function getGPCRlist() {
      const results = await axios.get(`${IP}/gpcrlist/`);
      setGpcrlist(results.data.data);
    }
    getGPCRlist();

    async function getDruglist() {
      const results = await axios.get(`${IP}/drugDBID/`);
      setDruglist(results.data.data);
    }
    getDruglist();

    async function getchemblist() {
      const results = await axios.get(`${IP}/chemblName/`);
      setChemblist(results.data.data);
    }
    getchemblist();
  }, []);

  const uniprotId = [];
  const geneName = [];
  const id_name_pair = [];

  if (gpcrlist.length > 0) {
    gpcrlist.map((c) => {
      uniprotId.push(c.uniprot_id);
      geneName.push(c.gene_name);
      id_name_pair.push(`${c.uniprot_id}: ${c.gene_name}`);
      return null;
    });
  }

  // console.log(chemblist);

  return (
    <Container fluid>
      <br />
      <br />
      <br />
      <Row>
        <Col className="col-4 mx-auto" style={{ minWidth: "30rem" }}>
          <br />
          <Row className="text-center">
            <div className="text-center">
              {/* <h2>7TM Knowledge Base</h2> */}
              <img
                className="col-8 ms-3"
                style={{ minWidth: "15rem" }}
                src={logo}
                alt="7TM Web Logo"
              />
            </div>
            <br />
            <br />
            <p className="mt-2 fst-italic fw-bold">
              a knowledgebase for data and analysis <br /> on
              seven-transmembrane receptors.
            </p>
          </Row>
          <br />
          <br />
          <Row className="text-center">
            <h6 className="mb-1 ">
              Query your favorite 7TM receptors and ligands.
            </h6>
          </Row>
          <br />
          <Row>
            <SearchBar drug={druglist} gpcr={gpcrlist} ligand={chemblist} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default MajorSearch;
