import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import AlphabeticalList from "../utilis/AlphabeticalList";
import Alphalist from "./common/Alphalist";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function Drug(props) {
  const [druglist, setDruglist] = useState([]);

  useEffect(() => {
    async function getDruglilst() {
      const results = await axios.get(`${IP}/drugDBID/`);
      // setDruglist(results.data.data.map((c) => c.drug_name));
      setDruglist(results.data.data.map((c) => `${c.drug_id}:${c.drug_name}`));
    }
    getDruglilst();
  }, []);

  const aList = druglist && AlphabeticalList(druglist, "A");
  const bList = druglist && AlphabeticalList(druglist, "B");
  const cList = druglist && AlphabeticalList(druglist, "C");
  const dList = druglist && AlphabeticalList(druglist, "D");
  const eList = druglist && AlphabeticalList(druglist, "E");
  const fList = druglist && AlphabeticalList(druglist, "F");
  const gList = druglist && AlphabeticalList(druglist, "G");
  const hList = druglist && AlphabeticalList(druglist, "H");
  const iList = druglist && AlphabeticalList(druglist, "I");
  const jList = druglist && AlphabeticalList(druglist, "J");
  const kList = druglist && AlphabeticalList(druglist, "K");
  const lList = druglist && AlphabeticalList(druglist, "L");
  const mList = druglist && AlphabeticalList(druglist, "M");
  const nList = druglist && AlphabeticalList(druglist, "N");
  const oList = druglist && AlphabeticalList(druglist, "O");
  const pList = druglist && AlphabeticalList(druglist, "P");
  const qList = druglist && AlphabeticalList(druglist, "Q");
  const rList = druglist && AlphabeticalList(druglist, "R");
  const sList = druglist && AlphabeticalList(druglist, "S");
  const tList = druglist && AlphabeticalList(druglist, "T");
  const uList = druglist && AlphabeticalList(druglist, "U");
  const vList = druglist && AlphabeticalList(druglist, "V");
  const wList = druglist && AlphabeticalList(druglist, "W");
  const xList = druglist && AlphabeticalList(druglist, "X");
  const yList = druglist && AlphabeticalList(druglist, "Y");
  const zList = druglist && AlphabeticalList(druglist, "Z");

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <Container>
        <Row>
          <h5 className="ms-4 ps-0">Alphabetic Indexes</h5>
        </Row>
        <br />
        <Row className="ms-0">
          <Col className="col-6 fw-bold">
            <HashLink className="mx-1" to="/drug#A">
              A
            </HashLink>
            <HashLink className="mx-1" to="/drug#B">
              B
            </HashLink>
            <HashLink className="mx-1" to="/drug#C">
              C
            </HashLink>
            <HashLink className="mx-1" to="/drug#D">
              D
            </HashLink>
            <HashLink className="mx-1" to="/drug#E">
              E
            </HashLink>
            <HashLink className="mx-1" to="/drug#F">
              F
            </HashLink>
            <HashLink className="mx-1" to="/drug#G">
              G
            </HashLink>
            <HashLink className="mx-1" to="/drug#H">
              H
            </HashLink>
            <HashLink className="mx-1" to="/drug#I">
              I
            </HashLink>
            <HashLink className="mx-1" to="/drug#J">
              J
            </HashLink>
            <HashLink className="mx-1" to="/drug#K">
              K
            </HashLink>
            <HashLink className="mx-1" to="/drug#L">
              L
            </HashLink>
            <HashLink className="mx-1" to="/drug#M">
              M
            </HashLink>
            <HashLink className="mx-1" to="/drug#N">
              N
            </HashLink>
            <HashLink className="mx-1" to="/drug#O">
              O
            </HashLink>
            <HashLink className="mx-1" to="/drug#P">
              P
            </HashLink>
            <HashLink className="mx-1" to="/drug#Q">
              Q
            </HashLink>
            <HashLink className="mx-1" to="/drug#R">
              R
            </HashLink>
            <HashLink className="mx-1" to="/drug#S">
              S
            </HashLink>
            <HashLink className="mx-1" to="/drug#T">
              T
            </HashLink>
            <HashLink className="mx-1" to="/drug#U">
              U
            </HashLink>
            <HashLink className="mx-1" to="/drug#V">
              V
            </HashLink>
            <HashLink className="mx-1" to="/drug#W">
              W
            </HashLink>
            <HashLink className="mx-1" to="/drug#X">
              X
            </HashLink>
            <HashLink className="mx-1" to="/drug#Y">
              Y
            </HashLink>
            <HashLink className="mx-1" to="/drug#Z">
              Z
            </HashLink>
          </Col>
        </Row>

        <br />
        <br />
        <Alphalist className="my-5" list={aList} letter="A" />
        <Alphalist className="my-5" list={bList} letter="B" />
        <Alphalist className="my-5" list={cList} letter="C" />
        <Alphalist className="my-5" list={dList} letter="D" />
        <Alphalist className="my-5" list={eList} letter="E" />
        <Alphalist className="my-5" list={fList} letter="F" />
        <Alphalist className="my-5" list={gList} letter="G" />
        <Alphalist className="my-5" list={hList} letter="H" />
        <Alphalist className="my-5" list={iList} letter="I" />
        <Alphalist className="my-5" list={jList} letter="J" />
        <Alphalist className="my-5" list={kList} letter="K" />
        <Alphalist className="my-5" list={lList} letter="L" />
        <Alphalist className="my-5" list={mList} letter="M" />
        <Alphalist className="my-5" list={nList} letter="N" />
        <Alphalist className="my-5" list={oList} letter="O" />
        <Alphalist className="my-5" list={pList} letter="P" />
        <Alphalist className="my-5" list={qList} letter="Q" />
        <Alphalist className="my-5" list={rList} letter="R" />
        <Alphalist className="my-5" list={sList} letter="S" />
        <Alphalist className="my-5" list={tList} letter="T" />
        <Alphalist className="my-5" list={uList} letter="U" />
        <Alphalist className="my-5" list={vList} letter="V" />
        <Alphalist className="my-5" list={wList} letter="W" />
        <Alphalist className="my-5" list={xList} letter="X" />
        <Alphalist className="my-5" list={yList} letter="Y" />
        <Alphalist className="my-5" list={zList} letter="Z" />
      </Container>

      <Footer />
    </div>
  );
}

export default Drug;
