export default function GetPopulationFlags(
  variation,
  canonical,
  canonical_seq,
  population
) {
  let flag = canonical_seq.split("");

  const newVarlist = variation.filter(
    (c) => c[population] > 0 && c.transcript === canonical
  );

  newVarlist.map((c) => {
    let pos = c.HGVS_consequence.match(/\d+/)[0];
    // variationLabel_temp[pos - 1] = "Y";
    if (flag[pos - 1] === "") {
      return (flag[
        pos - 1
      ] = `${c.HGVS_consequence}, ${population}: ${c[population]}`);
    } else {
      return (flag[pos - 1] = `${flag[pos - 1]}; ${
        c.HGVS_consequence
      }, ${population}: ${c[population]}`);
    }
  });

  return flag;
}
