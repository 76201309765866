import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function GPCRClass({ data, family, color }) {
  return (
    <Container>
      <Row className="my-3">
        <Col
          className="text-center text-white fw-bold fs-4 col-auto px-0 me-3"
          style={{ writingMode: "vertical-rl", backgroundColor: color }}
        >
          {family}
        </Col>
        <Col>
          <ul
            style={{
              columnCount: "auto",
              columnWidth: "5rem",
              listStyleType: "none",
              padding: "0px",
              margin: "0px",
            }}
          >
            {data.length
              ? data.map((c) => (
                  <li key={c.split(":")[1]}>
                    <Link to={`/gpcr/${c.split(":")[1]}`}>
                      {c.split(":")[0]}
                    </Link>
                  </li>
                ))
              : "No Data loaded"}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default GPCRClass;
