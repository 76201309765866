import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import { Form, Button, Container, Row, Col, Table } from "react-bootstrap";
// import BioPredScatter from "./common/figure/BioPredScatter";
import { toast } from "react-toastify";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function Affinity(props) {
  // const [gpcr, setGPCR] = useState("");
  const [smile, setSmile] = useState("");
  const [data, setData] = useState("");
  const [prediction, setPrediction] = useState("");
  const [gpcrlist, setGpcrlist] = useState([]);
  // const navigate = useNavigate();
  // const allGPCR = [
  //   "ACKR3",
  //   "ADCYAP1R1",
  //   "ADORA1",
  //   "ADORA2A",
  //   "ADORA2B",
  //   "ADORA3",
  //   "ADRA1A",
  //   "ADRA1B",
  //   "ADRA1D",
  //   "ADRA2A",
  //   "ADRA2B",
  //   "ADRA2C",
  //   "ADRB1",
  //   "ADRB2",
  //   "ADRB3",
  //   "AGTR1",
  //   "AGTR2",
  //   "APLNR",
  //   "APP",
  //   "AVPR1A",
  //   "AVPR1B",
  //   "AVPR2",
  //   "BDKRB1",
  //   "BDKRB2",
  //   "BRS3",
  //   "C3AR1",
  //   "C5AR1",
  //   "CALCR",
  //   "CALCRL",
  //   "CASR",
  //   "CCKAR",
  //   "CCKBR",
  //   "CCR1",
  //   "CCR10",
  //   "CCR2",
  //   "CCR3",
  //   "CCR4",
  //   "CCR5",
  //   "CCR6",
  //   "CCR8",
  //   "CCR9",
  //   "CCRL2",
  //   "CHRM1",
  //   "CHRM2",
  //   "CHRM3",
  //   "CHRM4",
  //   "CHRM5",
  //   "CMKLR1",
  //   "CNR1",
  //   "CNR2",
  //   "CRHR1",
  //   "CRHR2",
  //   "CX3CR1",
  //   "CXCR1",
  //   "CXCR2",
  //   "CXCR3",
  //   "CXCR4",
  //   "CXCR5",
  //   "CYSLTR1",
  //   "CYSLTR2",
  //   "DRD1",
  //   "DRD2",
  //   "DRD3",
  //   "DRD4",
  //   "DRD5",
  //   "EDNRA",
  //   "EDNRB",
  //   "F2R",
  //   "F2RL1",
  //   "F2RL3",
  //   "FFAR1",
  //   "FFAR3",
  //   "FFAR4",
  //   "FPR1",
  //   "FPR2",
  //   "FSHR",
  //   "FZD7",
  //   "GALR1",
  //   "GALR2",
  //   "GCGR",
  //   "GHSR",
  //   "GIPR",
  //   "GLP1R",
  //   "GLP2R",
  //   "GNRHR",
  //   "GPBAR1",
  //   "GPR119",
  //   "GPR139",
  //   "GPR142",
  //   "GPR174",
  //   "GPR183",
  //   "GPR34",
  //   "GPR35",
  //   "GPR39",
  //   "GPR4",
  //   "GPR52",
  //   "GPR55",
  //   "GPR6",
  //   "GPR84",
  //   "GPR88",
  //   "GRK2",
  //   "GRK3",
  //   "GRM1",
  //   "GRM2",
  //   "GRM3",
  //   "GRM4",
  //   "GRM5",
  //   "GRM8",
  //   "GRPR",
  //   "HCAR1",
  //   "HCAR2",
  //   "HCAR3",
  //   "HCRTR1",
  //   "HCRTR2",
  //   "HRH1",
  //   "HRH2",
  //   "HRH3",
  //   "HRH4",
  //   "HTR1A",
  //   "HTR1B",
  //   "HTR1D",
  //   "HTR1F",
  //   "HTR2A",
  //   "HTR2B",
  //   "HTR2C",
  //   "HTR4",
  //   "HTR5A",
  //   "HTR6",
  //   "HTR7",
  //   "KISS1R",
  //   "LHCGR",
  //   "LPAR1",
  //   "LPAR2",
  //   "LPAR3",
  //   "LPAR5",
  //   "LTB4R",
  //   "MC1R",
  //   "MC3R",
  //   "MC4R",
  //   "MC5R",
  //   "MCHR1",
  //   "MCHR2",
  //   "MLNR",
  //   "MRGPRX1",
  //   "MTNR1A",
  //   "MTNR1B",
  //   "MTOR",
  //   "NMBR",
  //   "NMUR1",
  //   "NMUR2",
  //   "NPBWR1",
  //   "NPFFR1",
  //   "NPFFR2",
  //   "NPY1R",
  //   "NPY2R",
  //   "NPY4R",
  //   "NPY5R",
  //   "NTSR1",
  //   "NTSR2",
  //   "OPRD1",
  //   "OPRK1",
  //   "OPRL1",
  //   "OPRM1",
  //   "OXER1",
  //   "OXTR",
  //   "P2RY1",
  //   "P2RY10",
  //   "P2RY12",
  //   "P2RY14",
  //   "P2RY2",
  //   "P2RY4",
  //   "P2RY6",
  //   "PDE4D",
  //   "PRLHR",
  //   "PROKR1",
  //   "PTAFR",
  //   "PTGDR",
  //   "PTGDR2",
  //   "PTGER1",
  //   "PTGER2",
  //   "PTGER3",
  //   "PTGER4",
  //   "PTGFR",
  //   "PTGIR",
  //   "PTK2",
  //   "PTK2B",
  //   "QRFPR",
  //   "RXFP1",
  //   "S1PR1",
  //   "S1PR2",
  //   "S1PR3",
  //   "S1PR4",
  //   "S1PR5",
  //   "SMO",
  //   "SSTR1",
  //   "SSTR2",
  //   "SSTR3",
  //   "SSTR4",
  //   "SSTR5",
  //   "SUCNR1",
  //   "TAAR1",
  //   "TACR1",
  //   "TACR2",
  //   "TACR3",
  //   "TAS2R14",
  //   "TAS2R8",
  //   "TBXA2R",
  //   "UTS2R",
  // ];

  const namePair = {};

  useEffect(() => {
    setData({
      // gpcr: gpcr,
      smile: smile,
    });
    async function getGPCRlist() {
      const results = await axios.get(`${IP}/gpcrlist/`);
      setGpcrlist(results.data.data);
    }
    getGPCRlist();
  }, [smile, prediction]);

  const handleSubmit = (e) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();

    toast.dark("Predicting... Please stay in this page", {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    axios
      .post(`${IP}/affinity/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success("Finished", {
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPrediction(response.data.data);

        //  smile: results, (id, [gpcr], [auc])
      })
      .catch((error) => {
        toast.error("Errors, please check your input", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  };

  if (gpcrlist) {
    gpcrlist.map((c) => {
      namePair[c.gene_name] = c.uniprot_id;
      return null;
    });
    // console.log(namePair);
  }

  return (
    <Fragment>
      <NavBar />
      <Row>
        <h5 className="my-5" style={{ textAlign: "center" }}>
          Predict GPCR-ligand affinity based on SMILES
        </h5>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col className="col-5 mx-5">
          <Form
            // validated={validated}
            onSubmit={handleSubmit}
            className="my-5 py-3"
            style={{ backgroundColor: "#1c2331" }}
          >
            <Container className="my-3">
              <Form.Group
                controlId="smileTextarea"
                onChange={(e) => setSmile(e.target.value)}
              >
                <Form.Label></Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={8}
                  placeholder="Please input the SMILEs of ligands here according to the format below."
                />
              </Form.Group>
              <Row className="mt-3">
                <Col>
                  <small className="text-white">
                    Input Format:
                    <br />
                    id1&nbsp;&nbsp;&nbsp;&nbsp;COCCCN1CCC(NC(=O)c2cc(Cl)c(N)c3c2OCC3)CC1
                    <br />
                    id2&nbsp;&nbsp;&nbsp;&nbsp;CCCN1CCC(CNC(=O)c2c3n(c4ccccc24)CCCO3)CC1
                    <br />
                  </small>
                </Col>
                <Col className="my-3  d-flex justify-content-end">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#E83F48",
                      border: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Col>
        <Col className="col-4 my-5">
          <h6>Predicted targeting GPCRs</h6>
          <p>
            <em>AUC: Area under the ROC Curve.</em>
          </p>

          <ul style={{ listStyleType: "none", margin: "0", padding: "0" }}>
            <Table>
              <thead>
                <tr key="h1">
                  <th className="col-1">IDs</th>
                  <th className="col-2">Target GPCRs</th>
                  <th className="col-2">AUCs</th>
                </tr>
              </thead>
              <tbody>
                {prediction.smile !== undefined ? (
                  prediction.smile.map((id) => (
                    <tr key={id[0]}>
                      <td className="col-1">
                        <li key={id[0]}>{id[0]}</li>
                      </td>
                      <td className="col-2">
                        {id[1].map((g) => (
                          <li key={g}>
                            <Link to={`/gpcr/${namePair[g]}`}>{g}</Link>
                          </li>
                        ))}
                      </td>
                      <td className="col-2">
                        {id[2].map((auc, index) => (
                          <li key={index}>{auc}</li>
                        ))}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </Table>
          </ul>
        </Col>
      </Row>
      <Footer />
    </Fragment>
  );
}

export default Affinity;
