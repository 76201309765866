import React, { useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Button } from "react-bootstrap";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function PopulationBar({ variation, canonical }) {
  // const [barWidth, setBarWidth] = useState(20);
  const barWidth = 20;
  let ref = useRef(null);

  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "figure.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  const population = [
    "allele_frequency",
    "AFR",
    "AMI",
    "AMR",
    "ASJ",
    "EAS",
    "FIN",
    "NFE",
    "MID",
    "SAS",
  ];

  return (
    <Container className="col-10">
      <div className="d-flex justify-content-end">
        <Button
          size="sm"
          className="border-0"
          style={{ backgroundColor: "#263878" }}
          onClick={downloadImage}
        >
          Download
        </Button>
      </div>
      <Container>
        <Bar
          ref={ref}
          data={{
            labels: [
              "All population",
              "AFR",
              "AMI",
              "AMR",
              "ASJ",
              "EAS",
              "FIN",
              "NFE",
              "MID",
              "SAS",
            ],
            datasets: [
              {
                label: "[0, 0.0001)",
                data: population.map((p) => {
                  return variation.filter((c) => c[p] < 0.0001 && c[p] > 0)
                    .length;
                }),
                backgroundColor: "#f9f917",
                stack: "Stack 1",
                barThickness: barWidth,
              },
              {
                label: "[0.0001, 0.001)",
                data: population.map((p) => {
                  return variation.filter((c) => c[p] < 0.001 && c[p] >= 0.0001)
                    .length;
                }),
                backgroundColor: "#fecc5c",
                stack: "Stack 2",
                barThickness: barWidth,
              },
              {
                label: "[0.001, 0.01)",
                data: population.map((p) => {
                  return variation.filter((c) => c[p] < 0.01 && c[p] >= 0.001)
                    .length;
                }),
                backgroundColor: "#fd8d3c",
                stack: "Stack 3",
                barThickness: barWidth,
              },
              {
                label: "[0.01, 0.1)",
                data: population.map((p) => {
                  return variation.filter((c) => c[p] < 0.1 && c[p] >= 0.01)
                    .length;
                }),
                backgroundColor: "#f03b20",
                stack: "Stack 4",
                barThickness: barWidth,
              },
              {
                label: "[0.1, 1]",
                data: population.map((p) => {
                  return variation.filter((c) => c[p] <= 1 && c[p] >= 0.1)
                    .length;
                }),
                backgroundColor: "#bd0026",
                stack: "Stack 5",
                barThickness: barWidth,
              },
            ],
          }}
          options={{
            aspectRatio: 2,
            scales: {
              x: {
                grid: {
                  display: false,
                  offset: true,
                },
                ticks: {
                  autoSkip: false,
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
                labels: { boxWidth: 30 },
              },
              title: {
                display: true,
                text: "Allele frequency of variants across populations",
                font: {
                  size: 20,
                },
                padding: {
                  bottom: 10,
                  top: 30,
                },
                color: "black",
              },
            },
          }}
        />
      </Container>
    </Container>
  );
}

export default PopulationBar;
