import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
// import { Link } from "react-router-dom";

function Documentation(props) {
  return (
    <div>
      <NavBar />
      {/* <Container
        fluid
        className="fs-5 text-center text-white fw-bold d-flex flex-column justify-content-center"
        style={{ height: "10vh", backgroundColor: "#1c2331" }}
      >
        <p className="mt-2 mb-1">
          <strong className="fs-4 me-1">7TMkb</strong>
        </p>
      </Container> */}
      <Container fluid className="my-5 col-6">
        <h5 id="about" className="fw-bold mt-5 mb-3">
          About 7TMkb
        </h5>
        <p>
          7TMkb is a knowledgebase for data mining and analysis of
          seven-transmembrane receptors. Multiple layers of information,
          including receptor variants, expression patterns, drug and ligand
          targets of 7TM receptors, as well as predictions of receptor
          properties are provided. It integrates with a number of sequence,
          chemistry and biologics repositories in the public domain (see links
          below), and extrapolates predictive models based on the multivariate
          data provided.
        </p>
        <h5 id="stats" className="fw-bold mt-5 mb-3">
          Overall description of the 7TM knowledge base
        </h5>
        <p>
          Currently, the 7TM knowledge base includes 896 human GPCRs, classified
          into 286 GPCRs are from Family A, 15 GPCRs from Family B, 33 GPCRs
          from Family B2, 19 GPCRs from Family C and 11 GPCRs from Family F
          according to{" "}
          <a href="https://gpcrdb.org/" target="_blank" rel="noreferrer">
            GPCRdb
          </a>
          ,{" "}
          <a
            href="https://www.ensembl.org/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Ensembl
          </a>{" "}
          and{" "}
          <a
            href="https://www.guidetopharmacology.org/"
            target="_blank"
            rel="noreferrer"
          >
            the IUPHAR/BPS Guide to PHARMACOLOGY
          </a>
          . Beside these, there are as well 427 olfactory GPCRs and 30 taste
          GPCRs. All these human GPCRs are extracted from{" "}
          <a href="https://www.uniprot.org/" target="_blank" rel="noreferrer">
            Uniprot
          </a>{" "}
          reviewed proteins. The sequences of GPCRs are fetched from{" "}
          <a
            href="https://www.ensembl.org/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Ensembl
          </a>
          . For all GPCRs, 314,451 transcript variants are obtained from{" "}
          <a
            href="https://gnomad.broadinstitute.org/"
            target="_blank"
            rel="noreferrer"
          >
            GnomAD v3
          </a>
          , 392,960 ligands targeting GPCRs are obtained from{" "}
          <a
            href="https://www.ebi.ac.uk/chembl/"
            target="_blank"
            rel="noreferrer"
          >
            Chembl
          </a>{" "}
          and 621 drugs targeting GPCRs are obtained from{" "}
          <a href="https://go.drugbank.com/" target="_blank" rel="noreferrer">
            DrugBank
          </a>
          . <br />
          <br />
          Search functions are provided for GPCRs, drugs and ligands. Searching
          GPCRs will also output classification of variants as well as provide
          demographic distributions of allele frequencies. Additionally, 7TMkb
          includes machine-learning models to predict EC50 and IC50 values for
          GPCRs interacting with ligands.
        </p>
        <h5 id="search" className="fw-bold mt-5 mb-3">
          Searching functions for GPCRs
        </h5>
        <p>
          Using 7TMkb you can search your favourite GPCR in either gene name or
          Uniprot ID for each GPCR. Also, you can find all human GPCRs grouped
          by families through “7TMs” (or ”GPCRs”) in Navbar. In each GPCR page
          you can find all the information for the queried GPCR, including basic
          sequence information, variation, expression, target ligands, and
          drugs. There is one functional table for variants and target ligands
          specifically, which you can conveniently filter and sort. Status of
          variant numbers, types, normalised tissue-specific transcript levels,
          and demographic distributions are plotted. Moreover, 7TMkb also
          provides a customizable snake-plot view allowing to color variants in
          each specific loci with the information of variant type and
          demographic information.
        </p>
        <h5 className="fw-bold mt-5 mb-3">Searching functions for ligands</h5>
        <p>
          All 7TM igands can be searched by their Chembl ID. In each ligand
          page, you can find their SMILES and target GPCRs. A function table for
          the target GPCRs is also provided. The ligand data is extracted from{" "}
          <a
            href="https://chembl.gitbook.io/chembl-interface-documentation/downloads"
            target="_blank"
            rel="noreferrer"
          >
            ChEMBLdb 29
          </a>
          .
        </p>
        <h5 className="fw-bold mt-5 mb-3">Searching functions for drugs</h5>
        <p>
          All drugs targeting 7TMs can be searched based on DrugBank ID and drug
          names. Additionally, 7TMkb provides an index for alphabetically
          ordered searches. In each drug page, you can find their drug types,
          DrugBank IDs, SMILES, and target GPCRs. The drug data is extracted
          from{" "}
          <a
            href="https://go.drugbank.com/releases/5-1-8"
            target="_blank"
            rel="noreferrer"
          >
            DrugBank version 5.1.8
          </a>
          .
        </p>
        <h5 id="data" className="fw-bold mt-5 mb-3">
          Variant data
        </h5>
        <p>
          The variant data is extracted from{" "}
          <a
            href="https://gnomad.broadinstitute.org/"
            target="_blank"
            rel="noreferrer"
          >
            GnomAD v3
          </a>
          . The variants are filtered out if the amino acid in reference
          conflicts with Ensembl protein sequences. Lastly, the allele frequency
          for each population is calculated using "Allele Count/Allele Number".
        </p>
        <h5 className="fw-bold mt-5 mb-3">Expression data</h5>
        The expression data is extracted from{" "}
        <a
          href="https://www.gtexportal.org/home/"
          target="_blank"
          rel="noreferrer"
        >
          GTEx
        </a>
        . All the expression values are TPM (Transcripts Per Million) values.
        <h5 id="predictor" className="fw-bold mt-5 mb-3">
          EC50/IC50 prediction
        </h5>
        Currently, there are EC50 and IC50 predictions for 130 and 166 GPCRs,
        respectively. A random forest model is used to make regressions between
        log-transformed EC50 or IC50 values with Morgan fingerprints for all
        GPCRs with at least 50 ligand records. For each GPCR, the data used for
        building predictive models is splitting data into 20%/80% as test set
        and train set, respectively. Only the models with R square value above
        0.3 are kept for the prediction.
        <br />
        <br />
        Before prediction, 7TMkb needs specification on the type of interaction
        as either EC50 or IC50, and then select the GPCRs to be analyzed. Each
        ligand should be put in as one line separately. The first column of each
        line should be the SMILES of ligand. It is highly recommended recommend
        to specify the first column as the name of each ligand to avoid
        confusion in the result output. Several ligands can be put in at the
        same time. Upload a file contains SMILES is also accepted. The result
        file contains value in nM unit of EC50 or IC50. The results can be
        exported as csv format. Additionally, scatter plots will show the
        performance of the model.
        <h5 id="predictor" className="fw-bold mt-5 mb-3">
          GPCR-ligand affinity prediction
        </h5>
        There are 206 human GPCRs used for ligand binding predictions in 7TMkb.
        We apply random matrix theory to predict binding affinity of ligands
        with Morgan fingerprints, targeting human GPCRs. For each GPCR, we build
        the binding set by keeping the ligands with no more than 1000nM affinity
        values from Chembl. The deploy set is constructed by randomly selecting
        1000 ligands from Chembl. The binding set ss then split into 20%/80% as
        test set and train set for the model construction. The models with AUC
        (Area under the Receiver operating characteristic Curve) above 0.7 are
        kept for the prediction. AUC ranges in value from 0 to 1. A model whose
        predictions are 100% wrong has an AUC of 0.0; one whose predictions are
        100% correct has an AUC of 1.0.
        <br />
        <br />
        For the prediction, you only need to provide the SMILES of ligands as
        indicated format. Several ligands can be put in at the same time. The
        results are listed by each ligands respectively, with all the targeting
        GPCRs and their AUC values which indicate the model performance.
        <h5 id="data" className="fw-bold mt-5 mb-3">
          7TMkb contributors
        </h5>
        <p>
          7TMkb is currently developed at the{" "}
          <a
            href="https://www.biosustain.dtu.dk/"
            target="_blank"
            rel="noreferrer"
          >
            Novo Nordisk Foundation Center for Biosustainability
          </a>{" "}
          by Xin Ma and Michael Krogh Jensen at the Technical University of
          Denmark. The server is hosted by{" "}
          <a
            href="https://computerome.dtu.dk/"
            target="_blank"
            rel="noreferrer"
          >
            DTU Computerome
          </a>
          . In its first version, 7TMkb is a resource for data mining and
          analysis of human GPCRs and their cognate ligands. Extensions to other
          receptor classes and other kingdoms of life is anticipated in the
          future with the main motivation to collect, structure, analyze, and
          engineer 7TM-based sensory systems.
          <br />
          <br />
          Suggestions for collaborations and inputs to 7TMkb are highly
          appreciated, and should be directed to
          <strong> 7TMkb@biosustain.dtu.dk</strong>
        </p>
        {/* <h5 id="aboutUs" className="fw-bold mt-5 mb-3">
          About SBTY
        </h5>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.biosustain.dtu.dk/research/research-groups/synthetic-biology-tools-for-yeast-michael-k-jensen"
        >
          The Synthetic Biology Tools for Yeast
        </a>
        &nbsp;section is located at The Novo Nordisk Foundation Center for
        Biosustainability, DTU Biosustain, and it is headed by Senior Researcher
        Michael Krogh Jensen.
        <br />
        <br />
        The Synthetic Biology Tools for Yeast section focuses on accelerating
        rational strain building and current labor-intensive trial and error
        engineering. Furthermore, the section focuses on providing more tools
        for high-throughput screening of cell factories. All projects are aimed
        towards global industrial needs within design, build and screening of
        new yeast cell factories. */}
      </Container>
      <Footer />
    </div>
  );
}

export default Documentation;
