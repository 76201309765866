export default function GetHGVSandMAF(
  variation,
  canonical,
  canonical_seq,
  varType
) {
  let flag = canonical_seq.split("");

  const newVarlist = variation.filter(
    (c) => c.VEP_annotation === varType && c.transcript === canonical
  );

  newVarlist.map((c) => {
    let pos = c.HGVS_consequence.match(/\d+/)[0];
    // variationLabel_temp[pos - 1] = "Y";
    if (flag[pos - 1] === "") {
      return (flag[pos - 1] = `${c.HGVS_consequence}, ${c.allele_frequency}`);
    } else {
      return (flag[pos - 1] = `${flag[pos - 1]}; ${c.HGVS_consequence}, ${
        c.allele_frequency
      }`);
    }
  });

  // console.log(flag);
  return flag;
}
