import React, { useState, useRef, useCallback } from "react";
import { Container, Button } from "react-bootstrap";
import CalSnakePos from "../../../utilis/CalSnakePos";
import GetHGVSandMAF from "../../../utilis/GetHGVSandMAF";
import GetPopulationFlags from "../../../utilis/GetPopulationFlags";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function Snakeplot({
  canonical,
  canonical_seq,
  snake_domain,
  variation,
  labels,
}) {
  const data = CalSnakePos(snake_domain);
  const [flags, setFlags] = useState();

  let ref = useRef(null);

  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "figure.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  const variationLabel = [];
  for (let i = 0; i < labels.length; i++) {
    variationLabel.push("N");
  }
  const [varLabel, setVarLabel] = useState([]);
  // const [customizedColor, setCustomizedColor] = useState("white");
  // setVarLabel(variationLabel);
  //console.log(varLabel);

  function VariantColor(varType, color) {
    const variationLabel_temp = [...variationLabel];
    const newVarlist = variation.filter(
      (c) => c.VEP_annotation === varType && c.transcript === canonical
    );
    newVarlist.map((c) => {
      let pos = c.HGVS_consequence.match(/\d+/)[0];
      variationLabel_temp[pos - 1] = color;
      return null;
    });
    setVarLabel(variationLabel_temp);
    // return [variationLabel_temp, color];
  }

  function PopulationColor(population) {
    const variationLabel_temp = [...variationLabel];
    const newVarlist = variation.filter(
      (c) => c[population] > 0 && c.transcript === canonical
    );
    newVarlist.map((c) => {
      let pos = c.HGVS_consequence.match(/\d+/)[0];
      // variationLabel_temp[pos - 1] = "Y";
      if (c.VEP_annotation === "missense_variant") {
        variationLabel_temp[pos - 1] = "#00A08A";
      } else if (c.VEP_annotation === "synonymous_variant") {
        variationLabel_temp[pos - 1] = "#FF0000";
      } else if (c.VEP_annotation === "frameshift_variant") {
        variationLabel_temp[pos - 1] = "#DC863B";
      } else if (c.VEP_annotation === "inframe_deletion") {
        variationLabel_temp[pos - 1] = "#F2AD00";
      } else if (c.VEP_annotation === "inframe_insertion") {
        variationLabel_temp[pos - 1] = "#9C964A";
      } else if (c.VEP_annotation === "protein_altering_variant") {
        variationLabel_temp[pos - 1] = "#CDC08C";
      } else if (c.VEP_annotation === "splice_acceptor_variant") {
        variationLabel_temp[pos - 1] = "#78B7C5";
      } else if (c.VEP_annotation === "splice_donor_variant") {
        variationLabel_temp[pos - 1] = "#EBCC2A";
      } else if (c.VEP_annotation === "splice_region_variant") {
        variationLabel_temp[pos - 1] = "#E1AF00";
      } else if (c.VEP_annotation === "start_lost") {
        variationLabel_temp[pos - 1] = "#F21A00";
      } else if (c.VEP_annotation === "stop_gained") {
        variationLabel_temp[pos - 1] = "#899DA4";
      } else if (c.VEP_annotation === "stop_lost") {
        variationLabel_temp[pos - 1] = "#C93312";
      } else if (c.VEP_annotation === "stop_retained_variant") {
        variationLabel_temp[pos - 1] = "#7D7768";
      }
      return null;
    });
    setVarLabel(variationLabel_temp);
  }

  const handleVariantColor = (varType, color, variation, canonical) => {
    VariantColor(varType, color);
    setFlags(GetHGVSandMAF(variation, canonical, canonical_seq, varType));
  };

  const handlePopulationColor = (
    population,
    variation,
    canonical,
    canonical_seq
  ) => {
    PopulationColor(population);
    setFlags(
      GetPopulationFlags(variation, canonical, canonical_seq, population)
    );
  };

  const handleRetColor = () => {
    setVarLabel(variationLabel);
    setFlags();
  };

  const bg_plugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "#E5E4E2";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  const backgroundColor = varLabel.length
    ? varLabel.map((c) => {
        return c === "N" ? "white" : c;
      })
    : "white";

  return (
    <Container>
      <Container className="d-flex justify-content-center flex-wrap">
        <Button
          size="sm"
          className="border-dark mx-1 my-1 px-1"
          style={{ backgroundColor: "white", color: "black" }}
          onClick={() => handleRetColor()}
        >
          Raw
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{
            backgroundColor: "#00A08A",
            border: "none",
          }}
          onClick={() =>
            handleVariantColor(
              "missense_variant",
              "#00A08A",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          missense
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#FF0000", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "synonymous_variant",
              "#FF0000",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          synonymous
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#DC863B", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "frameshift_variant",
              "#DC863B",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          frameshift
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#F2AD00", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "inframe_deletion",
              "#F2AD00",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          inframe del
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#9C964A", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "inframe_insertion",
              "#9C964A",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          inframe ins
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#CDC08C", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "protein_altering_variant",
              "#CDC08C",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          protein alt
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#78B7C5", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "splice_acceptor_variant",
              "#78B7C5",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          splice acceptor
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#EBCC2A", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "splice_donor_variant",
              "#EBCC2A",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          splice donor
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#E1AF00", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "splice_region_variant",
              "#E1AF00",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          splice region
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#F21A00", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "start_lost",
              "#F21A00",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          start lost
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#899DA4", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "stop_gained",
              "#899DA4",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          stop gained
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#C93312", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "stop_lost",
              "#C93312",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          stop lost
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "#7D7768", border: "none" }}
          onClick={() =>
            handleVariantColor(
              "stop_retained_variant",
              "#7D7768",
              variation,
              canonical,
              canonical_seq
            )
          }
        >
          stop retained
        </Button>
      </Container>
      <Container className="d-flex justify-content-center flex-wrap mb-1">
        <Button
          size="sm"
          className="border-0 mx-1 my-1 px-1"
          style={{ backgroundColor: "#263878" }}
          onClick={downloadImage}
        >
          Download
        </Button>
        <Button
          className="mx-1 my-1 px-1"
          size="sm"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("AFR", variation, canonical, canonical_seq)
          }
        >
          African/African American
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("AMI", variation, canonical, canonical_seq)
          }
        >
          Amish
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("AMR", variation, canonical, canonical_seq)
          }
        >
          Latino/Admixed American
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("ASJ", variation, canonical, canonical_seq)
          }
        >
          Ashkenazi Jewish
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("EAS", variation, canonical, canonical_seq)
          }
        >
          East Asian
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("FIN", variation, canonical, canonical_seq)
          }
        >
          Finnish
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("NFE", variation, canonical, canonical_seq)
          }
        >
          European(non-Finnish)
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("MID", variation, canonical, canonical_seq)
          }
        >
          Middle Eastern
        </Button>
        <Button
          size="sm"
          className="mx-1 my-1 px-1"
          style={{ backgroundColor: "black", border: "none" }}
          onClick={() =>
            handlePopulationColor("SAS", variation, canonical, canonical_seq)
          }
        >
          South Asian
        </Button>
      </Container>
      {data.length ? (
        <Scatter
          ref={ref}
          plugins={[ChartDataLabels, bg_plugin]}
          data={{
            labels: labels,
            datasets: [
              {
                data: data,
                pointRadius: 10,
                hoverRadius: 15,
                backgroundColor: backgroundColor,
                borderColor: "black",
                showLine: true,
              },
            ],
          }}
          options={{
            aspectRatio: 2,
            plugins: {
              datalabels: {
                anchor: "center",
                align: "center",
                formatter: function (value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
              },
              legend: { display: false },
              title: {
                display: true,
                text: "Snake plot of GPCR with variant information",
                font: {
                  size: 20,
                },
                padding: {
                  bottom: 20,
                  top: 20,
                },
                color: "black",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    if (flags) {
                      let label = flags[context.dataIndex];
                      return label;
                    } else {
                      let label = context.chart.data.labels[context.dataIndex];
                      return label;
                    }
                  },
                },
              },
            },
            scales: {
              y: {
                display: false,
                suggestedMin: 0,
                suggestedMax: 10,
              },
              x: {
                display: false,
                min: 1,
                max: 15,
              },
            },
          }}
        />
      ) : (
        <div className="mt-3 fw-bold text-center">
          Snake plot not applicable
        </div>
      )}
    </Container>
  );
}

export default Snakeplot;
