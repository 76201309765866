import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Homepage from "./components/Homepage";
import Biopred from "./components/Biopred";
import Affinity from "./components/Affinity";
import GPCR from "./components/GPCR";
import GPCRSingle from "./components/GPCRSingle";
import Drug from "./components/Drug";
import DrugSingle from "./components/DrugSingle";
import Documentation from "./components/Documentation";
import LigandSingle from "./components/LigandSingle";
import NotFoundPage from "./components/NotFoundPage";
import ScrollToTop from "./utilis/ScrollToTop";

function App() {
  return (
    <HelmetProvider>
      <React.Fragment>
        <Helmet>
          <title>7TM KnowledgeBase</title>
        </Helmet>
        <ScrollToTop />
        <Routes>
          <Route path="/biopred" exact element={<Biopred />} />
          <Route path="/affinity" exact element={<Affinity />} />
          <Route path="/gpcr/:id/" exact element={<GPCRSingle />} />
          <Route path="/drug/" exact element={<Drug />}></Route>
          <Route path="/drug/:id/" exact element={<DrugSingle />} />
          <Route path="/chembl/:id" exact element={<LigandSingle />} />
          <Route path="/gpcr/" exact element={<GPCR />} />
          <Route path="/help/" exact element={<Documentation />} />
          <Route path="/" exact element={<Homepage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Fragment>
    </HelmetProvider>
  );
}

export default App;
