import React, { useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Button } from "react-bootstrap";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

//  color : Darjeeling1, moonrise3, Royal1

function SNPBarplot({ variation, transcript }) {
  // const [barWidth, setBarWidth] = useState(50);
  const barWidth = 50;
  let ref = useRef(null);

  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "figure.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  return (
    <Container className="col-8">
      <div className="d-flex justify-content-end">
        <Button
          size="sm"
          className="border-0"
          style={{ backgroundColor: "#263878" }}
          onClick={downloadImage}
        >
          Download
        </Button>
      </div>
      <Container>
        <Bar
          ref={ref}
          data={{
            labels: transcript,
            datasets: [
              {
                label: "3 prime UTR variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "3_prime_UTR_variant"
                  ).length;
                }),
                backgroundColor: "#5BBCD6",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "5 prime UTR variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "5_prime_UTR_variant"
                  ).length;
                }),
                backgroundColor: "#3B9AB2",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "coding sequence variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "coding_sequence_variant"
                  ).length;
                }),
                backgroundColor: "#F4B5BD",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "frameshift variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "frameshift_variant"
                  ).length;
                }),

                backgroundColor: "#DC863B",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "incomplete terminal codon variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "incomplete_terminal_codon_variant"
                  ).length;
                }),
                backgroundColor: "#85D4E3",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "inframe deletion",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "inframe_deletion"
                  ).length;
                }),

                backgroundColor: "#F2AD00",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "inframe insertion",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "inframe_insertion"
                  ).length;
                }),
                backgroundColor: "#9C964A",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "intron variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "intron_variant"
                  ).length;
                }),

                backgroundColor: "#F98400",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "missense variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "missense_variant"
                  ).length;
                }),
                backgroundColor: "#00A08A",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "non-coding transcript exon variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "non_coding_transcript_exon_variant"
                  ).length;
                }),
                backgroundColor: "#FAD77B",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "protein altering variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "protein_altering_variant"
                  ).length;
                }),

                backgroundColor: "#CDC08C",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "splice_acceptor_variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "splice_acceptor_variant"
                  ).length;
                }),
                backgroundColor: "#78B7C5",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "splice donor variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "splice_donor_variant"
                  ).length;
                }),
                backgroundColor: "#EBCC2A",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "splice region variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "splice_region_variant"
                  ).length;
                }),
                backgroundColor: "#E1AF00",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "start lost",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp && c.VEP_annotation === "start_lost"
                  ).length;
                }),
                backgroundColor: "#F21A00",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "stop gained",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp && c.VEP_annotation === "stop_gained"
                  ).length;
                }),
                backgroundColor: "#899DA4",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "stop lost",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp && c.VEP_annotation === "stop_lost"
                  ).length;
                }),
                backgroundColor: "#C93312",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "stop retained variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "stop_retained_variant"
                  ).length;
                }),
                backgroundColor: "#7D7768",
                stack: "Stack 0",
                barThickness: barWidth,
              },
              {
                label: "synonymous variant",
                data: transcript.map((tp) => {
                  return variation.filter(
                    (c) =>
                      c.transcript === tp &&
                      c.VEP_annotation === "synonymous_variant"
                  ).length;
                }),
                backgroundColor: "#FF0000",
                stack: "Stack 0",
                barThickness: barWidth,
              },
            ],
          }}
          options={{
            aspectRatio: 1.5,
            scales: {
              x: {
                grid: {
                  display: false,
                  offset: true,
                },
                ticks: {
                  autoSkip: false,
                  maxRotation: 30,
                  minRotation: 30,
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: { boxWidth: 30 },
              },
              title: {
                display: true,
                text: "Numbers of variants in different transcripts",
                font: {
                  size: 20,
                },
                padding: {
                  bottom: 30,
                  top: 30,
                },
                color: "black",
              },
            },
          }}
        />
      </Container>
    </Container>
  );
}

export default SNPBarplot;
