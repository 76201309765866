import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import BioPredScatter from "./common/figure/BioPredScatter";
import { toast } from "react-toastify";
import IPaddress from "../services/IPaddress";
import { CSVLink } from "react-csv";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const IP = IPaddress();

function Biopred(props) {
  const selectInputRef = useRef();
  const uploadFileRef = useRef();
  const [gpcr, setGPCR] = useState("");
  const [smile, setSmile] = useState("");
  const [data, setData] = useState("");
  const [prediction, setPrediction] = useState("");
  const [predictionHeader, setPredictionHeader] = useState([]);
  const [predictionRes, setPredictionRes] = useState([]);
  const [targetType, setTargetType] = useState("EC50");
  const [selectedUploadFile, setSelectedUploadFile] = useState("");
  const animatedComponents = makeAnimated();

  // const [validated, setValidated] = useState(false);

  const ec50GPCR = [
    { value: "ACKR3", label: "ACKR3" },
    { value: "ADORA1", label: "ADORA1" },
    { value: "ADORA2A", label: "ADORA2A" },
    { value: "ADORA2B", label: "ADORA2B" },
    { value: "ADORA3", label: "ADORA3" },
    { value: "ADRA1A", label: "ADRA1A" },
    { value: "ADRA1B", label: "ADRA1B" },
    { value: "ADRA1D", label: "ADRA1D" },
    { value: "ADRA2A", label: "ADRA2A" },
    { value: "ADRA2C", label: "ADRA2C" },
    { value: "ADRB1", label: "ADRB1" },
    { value: "ADRB2", label: "ADRB2" },
    { value: "ADRB3", label: "ADRB3" },
    { value: "AGTR1", label: "AGTR1" },
    { value: "APLNR", label: "APLNR" },
    { value: "APP", label: "APP" },
    { value: "AVPR1A", label: "AVPR1A" },
    { value: "AVPR1B", label: "AVPR1B" },
    { value: "AVPR2", label: "AVPR2" },
    { value: "BRS3", label: "BRS3" },
    { value: "CALCR", label: "CALCR" },
    { value: "CASR", label: "CASR" },
    { value: "CCKAR", label: "CCKAR" },
    { value: "CCKBR", label: "CCKBR" },
    { value: "CCR5", label: "CCR5" },
    { value: "CCR8", label: "CCR8" },
    { value: "CHRM1", label: "CHRM1" },
    { value: "CHRM2", label: "CHRM2" },
    { value: "CHRM4", label: "CHRM4" },
    { value: "CHRM5", label: "CHRM5" },
    { value: "CMKLR1", label: "CMKLR1" },
    { value: "CNR1", label: "CNR1" },
    { value: "CNR2", label: "CNR2" },
    { value: "CXCR4", label: "CXCR4" },
    { value: "DRD1", label: "DRD1" },
    { value: "DRD2", label: "DRD2" },
    { value: "DRD3", label: "DRD3" },
    { value: "DRD4", label: "DRD4" },
    { value: "F2RL3", label: "F2RL3" },
    { value: "FFAR1", label: "FFAR1" },
    { value: "FFAR2", label: "FFAR2" },
    { value: "FFAR3", label: "FFAR3" },
    { value: "FFAR4", label: "FFAR4" },
    { value: "FPR1", label: "FPR1" },
    { value: "FPR2", label: "FPR2" },
    { value: "GCGR", label: "GCGR" },
    { value: "GHSR", label: "GHSR" },
    { value: "GIPR", label: "GIPR" },
    { value: "GLP1R", label: "GLP1R" },
    { value: "GPBAR1", label: "GPBAR1" },
    { value: "GPR119", label: "GPR119" },
    { value: "GPR17", label: "GPR17" },
    { value: "GPR174", label: "GPR174" },
    { value: "GPR34", label: "GPR34" },
    { value: "GPR35", label: "GPR35" },
    { value: "GPR55", label: "GPR55" },
    { value: "GPR6", label: "GPR6" },
    { value: "GPR84", label: "GPR84" },
    { value: "GPR88", label: "GPR88" },
    { value: "GRM1", label: "GRM1" },
    { value: "GRM2", label: "GRM2" },
    { value: "GRM3", label: "GRM3" },
    { value: "GRM4", label: "GRM4" },
    { value: "GRM5", label: "GRM5" },
    { value: "GRM7", label: "GRM7" },
    { value: "HCAR1", label: "HCAR1" },
    { value: "HCAR2", label: "HCAR2" },
    { value: "HCAR3", label: "HCAR3" },
    { value: "HCRTR1", label: "HCRTR1" },
    { value: "HCRTR2", label: "HCRTR2" },
    { value: "HRH1", label: "HRH1" },
    { value: "HRH3", label: "HRH3" },
    { value: "HRH4", label: "HRH4" },
    { value: "HTR1A", label: "HTR1A" },
    { value: "HTR1B", label: "HTR1B" },
    { value: "HTR1D", label: "HTR1D" },
    { value: "HTR1F", label: "HTR1F" },
    { value: "HTR2A", label: "HTR2A" },
    { value: "HTR2B", label: "HTR2B" },
    { value: "HTR2C", label: "HTR2C" },
    { value: "HTR4", label: "HTR4" },
    { value: "HTR6", label: "HTR6" },
    { value: "KISS1R", label: "KISS1R" },
    { value: "LPAR1", label: "LPAR1" },
    { value: "LPAR2", label: "LPAR2" },
    { value: "LPAR3", label: "LPAR3" },
    { value: "MC1R", label: "MC1R" },
    { value: "MC3R", label: "MC3R" },
    { value: "MC4R", label: "MC4R" },
    { value: "MC5R", label: "MC5R" },
    { value: "MCHR1", label: "MCHR1" },
    { value: "MLNR", label: "MLNR" },
    { value: "NMUR1", label: "NMUR1" },
    { value: "NMUR2", label: "NMUR2" },
    { value: "NPY1R", label: "NPY1R" },
    { value: "NPY2R", label: "NPY2R" },
    { value: "NPY4R", label: "NPY4R" },
    { value: "NTSR1", label: "NTSR1" },
    { value: "NTSR2", label: "NTSR2" },
    { value: "OPRD1", label: "OPRD1" },
    { value: "OPRK1", label: "OPRK1" },
    { value: "OPRL1", label: "OPRL1" },
    { value: "OPRM1", label: "OPRM1" },
    { value: "OXTR", label: "OXTR" },
    { value: "P2RY1", label: "P2RY1" },
    { value: "P2RY10", label: "P2RY10" },
    { value: "P2RY12", label: "P2RY12" },
    { value: "P2RY2", label: "P2RY2" },
    { value: "P2RY4", label: "P2RY4" },
    { value: "P2RY6", label: "P2RY6" },
    { value: "PTGDR", label: "PTGDR" },
    { value: "PTGER1", label: "PTGER1" },
    { value: "PTGER2", label: "PTGER2" },
    { value: "PTGER3", label: "PTGER3" },
    { value: "PTGER4", label: "PTGER4" },
    { value: "PTGIR", label: "PTGIR" },
    { value: "PTH1R", label: "PTH1R" },
    { value: "QRFPR", label: "QRFPR" },
    { value: "S1PR1", label: "S1PR1" },
    { value: "S1PR2", label: "S1PR2" },
    { value: "S1PR3", label: "S1PR3" },
    { value: "S1PR4", label: "S1PR4" },
    { value: "S1PR5", label: "S1PR5" },
    { value: "SMO", label: "SMO" },
    { value: "SSTR3", label: "SSTR3" },
    { value: "TACR3", label: "TACR3" },
    { value: "TBXA2R", label: "TBXA2R" },
    { value: "TSHR", label: "TSHR" },
    { value: "UTS2R", label: "UTS2R" },
    { value: "VIPR2", label: "VIPR2" },
  ];

  const ic50GPCR = [
    { value: "ADCYAP1R1", label: "ADCYAP1R1" },
    { value: "ADORA1", label: "ADORA1" },
    { value: "ADORA2A", label: "ADORA2A" },
    { value: "ADORA2B", label: "ADORA2B" },
    { value: "ADORA3", label: "ADORA3" },
    { value: "ADRA1A", label: "ADRA1A" },
    { value: "ADRA1D", label: "ADRA1D" },
    { value: "ADRA2A", label: "ADRA2A" },
    { value: "ADRA2B", label: "ADRA2B" },
    { value: "ADRA2C", label: "ADRA2C" },
    { value: "ADRB1", label: "ADRB1" },
    { value: "ADRB2", label: "ADRB2" },
    { value: "AGTR1", label: "AGTR1" },
    { value: "AGTR2", label: "AGTR2" },
    { value: "APLNR", label: "APLNR" },
    { value: "APP", label: "APP" },
    { value: "AVPR1A", label: "AVPR1A" },
    { value: "AVPR1B", label: "AVPR1B" },
    { value: "AVPR2", label: "AVPR2" },
    { value: "BDKRB1", label: "BDKRB1" },
    { value: "BDKRB2", label: "BDKRB2" },
    { value: "BRS3", label: "BRS3" },
    { value: "C5AR1", label: "C5AR1" },
    { value: "CALCR", label: "CALCR" },
    { value: "CALCRL", label: "CALCRL" },
    { value: "CASR", label: "CASR" },
    { value: "CCKAR", label: "CCKAR" },
    { value: "CCKBR", label: "CCKBR" },
    { value: "CCR1", label: "CCR1" },
    { value: "CCR10", label: "CCR10" },
    { value: "CCR2", label: "CCR2" },
    { value: "CCR3", label: "CCR3" },
    { value: "CCR4", label: "CCR4" },
    { value: "CCR5", label: "CCR5" },
    { value: "CCR6", label: "CCR6" },
    { value: "CCR8", label: "CCR8" },
    { value: "CHRM1", label: "CHRM1" },
    { value: "CHRM2", label: "CHRM2" },
    { value: "CHRM3", label: "CHRM3" },
    { value: "CHRM4", label: "CHRM4" },
    { value: "CHRM5", label: "CHRM5" },
    { value: "CMKLR1", label: "CMKLR1" },
    { value: "CNR1", label: "CNR1" },
    { value: "CNR2", label: "CNR2" },
    { value: "CRHR2", label: "CRHR2" },
    { value: "CXCR1", label: "CXCR1" },
    { value: "CXCR2", label: "CXCR2" },
    { value: "CXCR3", label: "CXCR3" },
    { value: "CXCR4", label: "CXCR4" },
    { value: "CXCR5", label: "CXCR5" },
    { value: "CXCR6", label: "CXCR6" },
    { value: "CYSLTR1", label: "CYSLTR1" },
    { value: "CYSLTR2", label: "CYSLTR2" },
    { value: "DRD1", label: "DRD1" },
    { value: "DRD2", label: "DRD2" },
    { value: "DRD3", label: "DRD3" },
    { value: "EDNRA", label: "EDNRA" },
    { value: "EDNRB", label: "EDNRB" },
    { value: "F2R", label: "F2R" },
    { value: "F2RL3", label: "F2RL3" },
    { value: "FFAR1", label: "FFAR1" },
    { value: "FPR2", label: "FPR2" },
    { value: "FSHR", label: "FSHR" },
    { value: "GALR1", label: "GALR1" },
    { value: "GCGR", label: "GCGR" },
    { value: "GHSR", label: "GHSR" },
    { value: "GIPR", label: "GIPR" },
    { value: "GLP1R", label: "GLP1R" },
    { value: "GNRHR", label: "GNRHR" },
    { value: "GPR119", label: "GPR119" },
    { value: "GPR18", label: "GPR18" },
    { value: "GPR183", label: "GPR183" },
    { value: "GPR34", label: "GPR34" },
    { value: "GPR35", label: "GPR35" },
    { value: "GPR4", label: "GPR4" },
    { value: "GPR55", label: "GPR55" },
    { value: "GRK1", label: "GRK1" },
    { value: "GRK2", label: "GRK2" },
    { value: "GRK6", label: "GRK6" },
    { value: "GRM1", label: "GRM1" },
    { value: "GRM2", label: "GRM2" },
    { value: "GRM3", label: "GRM3" },
    { value: "GRM4", label: "GRM4" },
    { value: "GRM5", label: "GRM5" },
    { value: "GRM7", label: "GRM7" },
    { value: "GRPR", label: "GRPR" },
    { value: "HCAR2", label: "HCAR2" },
    { value: "HCRTR1", label: "HCRTR1" },
    { value: "HCRTR2", label: "HCRTR2" },
    { value: "HRH1", label: "HRH1" },
    { value: "HRH3", label: "HRH3" },
    { value: "HRH4", label: "HRH4" },
    { value: "HTR1A", label: "HTR1A" },
    { value: "HTR1B", label: "HTR1B" },
    { value: "HTR1D", label: "HTR1D" },
    { value: "HTR1E", label: "HTR1E" },
    { value: "HTR2A", label: "HTR2A" },
    { value: "HTR2C", label: "HTR2C" },
    { value: "HTR4", label: "HTR4" },
    { value: "HTR6", label: "HTR6" },
    { value: "HTR7", label: "HTR7" },
    { value: "KISS1R", label: "KISS1R" },
    { value: "LHCGR", label: "LHCGR" },
    { value: "LPAR1", label: "LPAR1" },
    { value: "LPAR2", label: "LPAR2" },
    { value: "LPAR3", label: "LPAR3" },
    { value: "LPAR5", label: "LPAR5" },
    { value: "LTB4R", label: "LTB4R" },
    { value: "MC1R", label: "MC1R" },
    { value: "MC3R", label: "MC3R" },
    { value: "MC4R", label: "MC4R" },
    { value: "MC5R", label: "MC5R" },
    { value: "MCHR1", label: "MCHR1" },
    { value: "MCHR2", label: "MCHR2" },
    { value: "MRGPRX1", label: "MRGPRX1" },
    { value: "MTNR1A", label: "MTNR1A" },
    { value: "MTNR1B", label: "MTNR1B" },
    { value: "MTOR", label: "MTOR" },
    { value: "NMUR1", label: "NMUR1" },
    { value: "NPBWR1", label: "NPBWR1" },
    { value: "NPY1R", label: "NPY1R" },
    { value: "NPY2R", label: "NPY2R" },
    { value: "NPY4R", label: "NPY4R" },
    { value: "NPY5R", label: "NPY5R" },
    { value: "OPRD1", label: "OPRD1" },
    { value: "OPRK1", label: "OPRK1" },
    { value: "OPRL1", label: "OPRL1" },
    { value: "OPRM1", label: "OPRM1" },
    { value: "OXER1", label: "OXER1" },
    { value: "P2RY1", label: "P2RY1" },
    { value: "P2RY12", label: "P2RY12" },
    { value: "P2RY14", label: "P2RY14" },
    { value: "P2RY2", label: "P2RY2" },
    { value: "PDE4D", label: "PDE4D" },
    { value: "PRLHR", label: "PRLHR" },
    { value: "PROKR1", label: "PROKR1" },
    { value: "PTAFR", label: "PTAFR" },
    { value: "PTGDR", label: "PTGDR" },
    { value: "PTGDR2", label: "PTGDR2" },
    { value: "PTGER1", label: "PTGER1" },
    { value: "PTGER3", label: "PTGER3" },
    { value: "PTGER4", label: "PTGER4" },
    { value: "PTGFR", label: "PTGFR" },
    { value: "PTGIR", label: "PTGIR" },
    { value: "PTH1R", label: "PTH1R" },
    { value: "PTK2", label: "PTK2" },
    { value: "PTK2B", label: "PTK2B" },
    { value: "QRFPR", label: "QRFPR" },
    { value: "S1PR1", label: "S1PR1" },
    { value: "S1PR2", label: "S1PR2" },
    { value: "S1PR3", label: "S1PR3" },
    { value: "S1PR4", label: "S1PR4" },
    { value: "S1PR5", label: "S1PR5" },
    { value: "SMO", label: "SMO" },
    { value: "SSTR1", label: "SSTR1" },
    { value: "SSTR2", label: "SSTR2" },
    { value: "SSTR3", label: "SSTR3" },
    { value: "SSTR4", label: "SSTR4" },
    { value: "SSTR5", label: "SSTR5" },
    { value: "TACR1", label: "TACR1" },
    { value: "TACR2", label: "TACR2" },
    { value: "TACR3", label: "TACR3" },
    { value: "TAS2R8", label: "TAS2R8" },
    { value: "TBXA2R", label: "TBXA2R" },
    { value: "TSHR", label: "TSHR" },
    { value: "UTS2R", label: "UTS2R" },
  ];
  //  async calling, the same as below
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // console.log(data);

  //   const results = await axios.post(
  //     "http://localhost:8000/gpcr/biopred/",
  //     data,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   setNewGPCR(results.data.data);
  // };

  useEffect(() => {
    setData({
      type: targetType,
      gpcr: gpcr,
      smile: smile,
    });
    setTargetType(targetType);
  }, [targetType, gpcr, smile, prediction, selectedUploadFile]);

  const handleSubmit = (e) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();

    toast.dark("Predicting... Please stay in this page", {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    axios
      .post(`${IP}/biopred/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success("Finished", {
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPrediction(response.data.data);
        setPredictionHeader(response.data.data.smile[0]);
        setPredictionRes(response.data.data.smile.slice(1));

        //  smile: results, (name, pvalue, nM) ----- old one, expired
        //  smile: [name, g1, g2, g3 ... ], [id1, v11, v21, ...], [id2, v21, v22...]
        // type: r2, mse, yexp, ypred
      })
      .catch((error) => {
        toast.error("Errors, please check your input", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  };

  const getOptionLabel = (gpcrName) => {
    return gpcrName.label;
  };

  const getOptionValue = (gpcrName) => {
    return gpcrName.value;
  };

  const handleGPCRChange = (selected) => {
    // console.log(selected);
    const selectedGPCR = [];
    for (const s of selected) {
      // console.log(s.value);
      selectedGPCR.push(s.value);
    }
    // console.log(selectedGPCR);
    setGPCR(selectedGPCR.join(","));
  };

  const handleFileChange = (e) => {
    // console.log(e.target.files[0]);
    setSelectedUploadFile(e.target.files[0]);
    if (e.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setSmile(e.target.result);
      };
    }
  };

  const handleRomoveFile = (e) => {
    setSelectedUploadFile("");
    uploadFileRef.current.value = "";
  };

  const handleUploadFile = (e) => {
    // console.log("upload file");

    if (selectedUploadFile) {
      // const fileReader = new FileReader();
      // fileReader.readAsText(selectedUploadFile, "UTF-8");
      // fileReader.onload = (e) => {
      //   // console.log(e.target.result);
      //   setSmile(e.target.result);
      // };

      e.preventDefault();

      toast.dark("Predicting... Please stay in this page", {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      axios
        .post(`${IP}/biopred/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          toast.success("Finished", {
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setPrediction(response.data.data);
          setPredictionHeader(response.data.data.smile[0]);
          setPredictionRes(response.data.data.smile.slice(1));

          //  smile: results, (name, pvalue, nM) ----- old one, expired
          //  smile: [name, g1, g2, g3 ... ], [id1, v11, v21, ...], [id2, v21, v22...]
          // type: r2, mse, yexp, ypred
        })
        .catch((error) => {
          toast.error("Errors, please check your input", {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    } else {
      alert("Please upload a file!");
    }
  };

  // console.log(prediction.type[3][2]);
  // console.log(R2);
  // console.log(prediction.type);
  // console.log(gpcr);
  // console.log(uploadFileRef.current.value);
  // console.log(smile);
  // console.log(selectedUploadFile);

  return (
    <Fragment>
      <NavBar />
      <Container className="my-5 col-8" style={{ minWidth: "40rem" }}>
        <h5 style={{ textAlign: "center" }}>
          Predict bioactivites of GPCRs targeting compounds based on SMILES
        </h5>
        <Form
          // validated={validated}
          onSubmit={handleSubmit}
          className="my-5 py-3"
          style={{ backgroundColor: "#1c2331" }}
        >
          <Row className="mt-3 mb-1 d-flex justify-content-center">
            <Col className="col-2">
              <Form.Select
                required
                aria-label="target_type"
                className=""
                onChange={(e) => {
                  setTargetType(e.target.value);
                  setPrediction("");
                  selectInputRef.current.clearValue();
                }}
              >
                <option value="EC50">EC50</option>
                <option value="IC50">IC50</option>
              </Form.Select>
            </Col>
            <Col className="col-1"></Col>
            <Col className="col-8">
              {/* <Form.Select
                required
                aria-label="gpcrs"
                onChange={(e) => {
                  setGPCR(e.target.value);
                  setPrediction("");
                }}
              >
                <option>Target GPCRs</option>
                {targetType === "EC50"
                  ? ec50GPCR.map((c) => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))
                  : ic50GPCR.map((c) => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
              </Form.Select> */}
              {targetType === "EC50" ? (
                <Select
                  ref={selectInputRef}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={selectedOption}
                  // onChange={setSelectedOption}
                  isMulti
                  options={ec50GPCR}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  onChange={handleGPCRChange}
                />
              ) : (
                <Select
                  ref={selectInputRef}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  // defaultValue={selectedOption}
                  // onChange={setSelectedOption}
                  isMulti
                  options={ic50GPCR}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  onChange={handleGPCRChange}
                />
              )}
            </Col>
          </Row>

          <Container className="my-3">
            <Form.Group
              controlId="smileTextarea"
              onChange={(e) => setSmile(e.target.value)}
            >
              <Form.Label></Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={8}
                placeholder="Please input the SMILEs of Compounds here according to the format below."
              />
            </Form.Group>
            <Row className="mt-3">
              <Col>
                <small className="text-white">
                  Input Format:
                  <br />
                  id1&nbsp;&nbsp;&nbsp;&nbsp;COCCCN1CCC(NC(=O)c2cc(Cl)c(N)c3c2OCC3)CC1
                  <br />
                  id2&nbsp;&nbsp;&nbsp;&nbsp;CCCN1CCC(CNC(=O)c2c3n(c4ccccc24)CCCO3)CC1
                  <br />
                </small>
              </Col>
              <Col className="my-3  d-flex justify-content-end">
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#E83F48",
                    border: "none",
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        <h6>
          Or, you can upload a file contains SMILES as the format indicated
          above
        </h6>
        <Row>
          <Form.Group className="my-3 col-4">
            {/* <Form.Label>Or, you can upload a SMILES file</Form.Label> */}
            <Form.Control
              type="file"
              ref={uploadFileRef}
              onChange={handleFileChange}
            />
          </Form.Group>
          <Button
            type="submit"
            style={{
              backgroundColor: "#E83F48",
              border: "none",
              fontWeight: "bold",
            }}
            className="col-1 my-3"
            onClick={handleUploadFile}
          >
            Upload
          </Button>
          <Button
            // type="submit"
            style={{
              backgroundColor: "#1c2331",
              border: "none",
              fontWeight: "bold",
            }}
            className="col-1 my-3 ms-2"
            onClick={handleRomoveFile}
          >
            Remove
          </Button>
        </Row>
        <br />
        <br />
        <Container>
          <Row>
            <Col className="ps-0">
              <h6>
                Predicted Results (nM) ({targetType}): {}{" "}
              </h6>
              <h6>
                {prediction.smile !== undefined ? (
                  <CSVLink data={prediction.smile}>Download</CSVLink>
                ) : (
                  ""
                )}
              </h6>
              {/* <p className="fst-italic fw-bold">Name pValue nM</p> */}
              {/* <ul style={{ listStyleType: "none", margin: "0", padding: "0" }}>
                {prediction.smile !== undefined
                  ? prediction.smile.map((c) => (
                      // <li key={c[0]}>
                      //   <strong>{c[0]}</strong>&nbsp;&nbsp;&nbsp;&nbsp;{c[1]}
                      //   &nbsp;&nbsp;&nbsp;&nbsp;{c[2]}
                      // </li>

                      <ul className="list-group list-group-horizontal">
                        {c.map((cc) => (
                          <li className="list-group-item">{cc}</li>
                        ))}
                      </ul>
                    ))
                  : ""}
              </ul> */}
              {prediction.smile !== undefined ? (
                <table className="table">
                  <thead>
                    <tr>
                      {predictionHeader.map((c) => (
                        <th key={c}>{c}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {predictionRes.map((c) => (
                      <tr key={c}>
                        {c.map((cc) => (
                          <td key={cc}>{cc}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </Col>
            {/* <Col className="col-1"></Col>
            <Col className="col-5">
              <h6 className="fw-bold ms-3">Model performance</h6>
              {prediction.type !== undefined ? (
                <div>
                  <ul
                    className="ms-3"
                    style={{ listStyleType: "none", margin: "0", padding: "0" }}
                  >
                    <li>
                      <strong>R2 value</strong>: {prediction.type[0]}
                    </li>
                    <li>
                      <strong>MSE</strong>: {prediction.type[1]}
                    </li>
                  </ul>
                  <div style={{ minWidth: "10rem" }} className="col-10 my-3">
                    <BioPredScatter
                      yexp={prediction.type[2]}
                      ypred={prediction.type[3]}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col> */}
          </Row>
        </Container>
      </Container>
      <br />
      <br />
      {prediction.type !== undefined ? (
        <Container>
          <h6>Performance of the models</h6>
          <br />
          <Container>
            <Row>
              {prediction.type.map((c) => (
                <Col key={c} className="col-3">
                  <ul
                    className="my-3 ms-5"
                    style={{
                      listStyleType: "none",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <li key="GPCR">
                      <strong>GPCR</strong>: {c[0]}
                    </li>
                    <li key="Rvalue">
                      <strong>R2 value</strong>: {c[1][0]}
                    </li>
                    <li key="MSE">
                      <strong>MSE</strong>: {c[1][1]}
                    </li>
                  </ul>
                  <div>
                    <BioPredScatter yexp={c[1][2]} ypred={c[1][3]} />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      ) : (
        ""
      )}

      <Footer />
    </Fragment>
  );
}

export default Biopred;
