export default function AlphabeticalList(list, startLetter) {
  const newList = [];
  list.map((c) => {
    if (c.split(":")[1].toUpperCase().startsWith(startLetter)) {
      return newList.push(c);
    }
    return null;
  });
  return newList.sort((a, b) => a.split(":")[1].localeCompare(b.split(":")[1]));
}
