import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Alphalist({ list, letter }) {
  return (
    <div id={letter}>
      <Row>
        <Col
          className="ms-4 text-center pt-2 pb-0 mb-3"
          style={{
            backgroundColor: "#263878",
            maxWidth: "50px",
            maxHeight: "50px",
          }}
        >
          <h5 className="text-white fw-bold">{letter}</h5>
        </Col>
      </Row>
      <Container>
        <ul
          style={{
            listStyleType: "none",
            padding: "0px",
            margin: "0px",
            columnCount: "auto",
            columnWidth: "10rem",
          }}
        >
          {list.map((c) => {
            return (
              <li
                style={{ float: "left", minWidth: "150px" }}
                className="my-1"
                key={c.split(":")[0]}
              >
                <Link to={c.split(":")[0]}>{c.split(":")[1]}</Link>
              </li>
            );
          })}
        </ul>
        <br />
        <br />
      </Container>
    </div>
  );
}

export default Alphalist;
