import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Accordion, Row, Col } from "react-bootstrap";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import FormatSeq from "../utilis/FormatSeq";
import VarTable from "./common/VarTable";
import LigandTable from "./common/LigandTable";
import Expression from "./common/figure/Expression";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function GPCRSingle(props) {
  const { id } = useParams();
  const [gpcr, setGpcr] = useState([]);
  const [seq, setSeq] = useState([]);
  const [drug, setDrug] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getGPCR() {
      try {
        const results = await axios.get(`${IP}/gpcrlist/${id}`);
        setGpcr(results.data.data);
      } catch {
        navigate("*");
      }
    }
    getGPCR();

    async function getSeq() {
      const results = await axios.get(`${IP}/seq/${id}`);
      setSeq(results.data.data);
    }
    getSeq();

    async function getDrug() {
      const results = await axios.get(`${IP}/drug/${id}/`);
      setDrug(results.data.data);
    }
    getDrug();
  }, [id, navigate]);

  // if (drug[0]) {
  //   console.log(drug[0].target_drugs);
  // }
  // console.log(gpcr.transcript_id);
  return (
    <div>
      <NavBar />
      {/* 1. basic information */}
      <Container id="gpcr_basic_inf" className="my-5 mx-5">
        <h5 className="section_title mb-4">1. Basic information</h5>
        <Container className="ms-3">
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              Gene name
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.gene_name}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              Class name
            </Col>
            <Col className="py-1 justify-content-start">
              {gpcr.classification}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              Uniprot id
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.uniprot_id}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              Uniprot entry
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.uniprot_name}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              Gene id
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.gene_id ? gpcr.gene_id : "NA in Ensembl"}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              transcript
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.transcript_id ? gpcr.transcript_id : "NA in Ensembl"}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              protein
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              {gpcr.protein_id ? gpcr.protein_id : "NA in Ensembl"}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col
              className="py-1 col-2 d-flex justify-content-end fw-bold text-uppercase text-white"
              style={{ backgroundColor: "#263878", maxWidth: "180px" }}
            >
              &nbsp;Target drugs
            </Col>
            <Col className="py-1 d-flex justify-content-start">
              <Container className="ps-0">
                <ul
                  style={{
                    listStyleType: "none",
                    padding: "0px",
                    margin: "0px",
                    columnCount: "auto",
                    columnWidth: "10rem",
                    // display: "none",
                  }}
                >
                  {drug[0] && drug[0].target_drugs !== "Na" ? (
                    drug.map((c) => {
                      const drugTemp = c.target_drugs.split(",");
                      drugTemp.sort((a, b) =>
                        a.split(":")[1].localeCompare(b.split(":")[1])
                      );
                      return drugTemp.map((d) => {
                        const drugname = d.split(":")[1];
                        const drugid = d.split(":")[0];
                        return (
                          <li key={drugid}>
                            <Link to={`/drug/${drugid}`}>{drugname}</Link>
                          </li>
                        );
                      });
                    })
                  ) : (
                    <li>NA in DataBank</li>
                  )}
                </ul>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* 2.sequence information */}
      <Container id="gpcr_seq_inf" className="ms-5 my-5 col-6">
        <h5 className="section_title mb-4">2. Seq information</h5>

        <div className="seq_container">
          <h6 className="mb-3">Canonical protein</h6>
          {gpcr.canonical_seq ? (
            <Accordion alwaysOpen defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {gpcr.protein_id ? gpcr.protein_id : "Canonical protein"}
                </Accordion.Header>
                <Accordion.Body className="px-0">
                  <pre className="seq border-dashed border-secondary py-3 px-3">
                    {FormatSeq(gpcr.canonical_seq)}
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <p>No sequence information</p>
          )}

          {seq.length > 1 ? (
            <div>
              <h6 className="mt-5 mb-3">Alternative sequences</h6>
              <Accordion alwaysOpen>
                {seq.map(
                  (c) =>
                    c.protein_id !== gpcr.protein_id && (
                      <Accordion.Item key={c.id} eventKey={c.id}>
                        <Accordion.Header>{c.protein_id}</Accordion.Header>
                        <Accordion.Body className="px-0">
                          <pre className="seq border-dashed border-secondary py-3 px-3">
                            {FormatSeq(c.protein_seq)}
                          </pre>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                )}
              </Accordion>
            </div>
          ) : (
            ""
          )}
        </div>
      </Container>
      {/* 3. Variation information */}
      <Container id="gpcr_variant" className="mt-5 ms-5">
        <h5 className="section_title mb-4">3. Variants</h5>
        <br />
        <h6 className="ms-1 mb-3">3.1 Variant Table</h6>
      </Container>
      <Container className="ms-4">
        <VarTable
          canonical={
            gpcr.transcript_id ? gpcr.transcript_id.split(".")[0] : "NA"
          }
          canonical_seq={gpcr.canonical_seq ? gpcr.canonical_seq : "NA"}
          snake_domain={gpcr.domain}
        />
      </Container>
      {/* 4. Expression */}
      <br />
      <br />
      <Container id="expression" className=" mt-5 ms-5">
        <h5 className="section_title mb-4">4. Expressions</h5>
        <br />
        <Container className="">
          {gpcr.gene_id ? (
            <Expression geneID={gpcr.gene_id} />
          ) : (
            <div className="fw-bold ps-2">Expression data not applicable</div>
          )}
        </Container>
      </Container>
      {/* 5. chembl ligands */}
      <Container id="chembl_ligands" className="mt-5 ms-5">
        <h5 className="section_title mb-4">5. Ligand targets</h5>
        <br />
      </Container>
      <Container className="ms-4">
        <LigandTable />
      </Container>
      <Footer />
    </div>
  );
}

export default GPCRSingle;
