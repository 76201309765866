import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function BioPredScatter({ yexp, ypred }) {
  const dataInput = [];

  for (let i = 0; i < yexp.length; i++) {
    dataInput.push({ x: yexp[i], y: ypred[i] });
  }

  const options = {
    aspectRatio: 1,
    scales: {
      y: {
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          autoSkip: false,
          stepSize: 1,

          font: {
            size: 14,
          },
          color: "black",
        },
        title: {
          display: true,
          text: "Predicted pValue",
          font: {
            size: 14,
          },
          color: "black",
        },
        // beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          autoSkip: false,
          stepSize: 1,
          font: {
            size: 14,
          },
          color: "black",
        },
        title: {
          display: true,
          text: "Experimental pValue",
          font: {
            size: 14,
          },
          color: "black",
        },
        // beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const data = {
    datasets: [
      {
        label: "A dataset",
        data: dataInput,
        backgroundColor: "#263878",
      },
    ],
  };
  // console.log(dataInput);
  return <Scatter options={options} data={data} />;
}

export default BioPredScatter;
