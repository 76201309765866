import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import GPCRClass from "./common/GPCRClass";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function GPCR(props) {
  const [gpcrlist, setGpcrlist] = useState([]);

  useEffect(() => {
    async function getlist() {
      const results = await axios.get(`${IP}/gpcrlist/`);
      setGpcrlist(results.data.data);
    }
    getlist();
  }, []);

  const classA =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "A")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classB =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "B")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classB2 =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "B2")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classC =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "C")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classF =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "F")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classOlfactory =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "Olfactory")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classTaste =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "Taste")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  const classOther =
    gpcrlist &&
    gpcrlist
      .filter((c) => c.classification === "Other")
      .map((c) => `${c.gene_name}:${c.uniprot_id}`);

  classA.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classB.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classB2.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classC.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classF.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classOlfactory.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classTaste.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));
  classOther.sort((a, b) => a.split(":")[0].localeCompare(b.split(":")[0]));

  return (
    <div>
      <NavBar />
      <Container className="my-5 ps-0">
        <h5>Human GPCRs grouped by family</h5>
      </Container>
      <GPCRClass data={classA} family="A" color="#d64161" />
      <GPCRClass data={classB} family="B" color="#86af49" />
      <GPCRClass data={classB2} family="B2" color="#a2b9bc" />
      <GPCRClass data={classC} family="C" color="#feb236" />
      <GPCRClass data={classF} family="F" color="#d6cbd3" />
      <GPCRClass data={classOlfactory} family="Olfactory" color="#80ced6" />
      <GPCRClass data={classTaste} family="Taste" color="#f18973" />
      <GPCRClass data={classOther} family="Other" color="#b2b2b2" />
      <Footer />
    </div>
  );
}

export default GPCR;
