import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function DrugSingle(props) {
  const { id } = useParams();
  const [drug, setDrug] = useState([]);
  const [gpcrlist, setGpcrlist] = useState([]);
  const navigate = useNavigate();

  const namePair = {};

  useEffect(() => {
    async function getDrug() {
      try {
        const results = await axios.get(`${IP}/drugDBID/${id}/`);
        setDrug(results.data.data);
        // console.log(results);
      } catch {
        navigate("*");
      }
    }
    getDrug();

    async function getGPCRlist() {
      const results = await axios.get(`${IP}/gpcrlist/`);
      setGpcrlist(results.data.data);
    }
    getGPCRlist();
  }, [id, navigate]);

  if (gpcrlist) {
    gpcrlist.map((c) => {
      namePair[c.uniprot_id] = c.gene_name;
      return null;
    });
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <NavBar />
      <br />
      <Container className="my-5 my-auto">
        <Row>
          <Col className="">
            <h4 className="">{drug.drug_name}</h4>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col
            className="py-2 col-2 text-end fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            Drug type
          </Col>
          <Col className="py-2">{drug.drug_type}</Col>
        </Row>
        <Row>
          <Col
            className="py-2 col-2 text-end fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            DrugBank ID
          </Col>
          <Col className="py-2">{drug.drug_id}</Col>
        </Row>
        <Row>
          <Col
            className="py-2 col-2 text-end fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            SMILES
          </Col>
          <Col className="py-2">
            {drug.smiles !== "Na" ? drug.smiles : "No information"}
          </Col>
        </Row>
        <Row>
          <Col
            className="py-2 col-2 text-end fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            Target GPCRs
          </Col>
          <Col className="py-2">
            <ul
              style={{ listStyleType: "none", padding: "0px", margin: "0px" }}
            >
              {drug.target_proteins &&
                drug.target_proteins.split(" ").map((c) => (
                  <li key={c}>
                    <Link to={`/gpcr/${c}`}>{namePair[c]}</Link>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default DrugSingle;
