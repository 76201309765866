import React, { useState, useEffect } from "react";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LigandTableChemblID from "./common/LigandTableChemblID";
import IPaddress from "../services/IPaddress";

const IP = IPaddress();

function LigandSingle(props) {
  const { id } = useParams();
  const [ligand, setLigand] = useState([]);
  const [gpcrlist, setGpcrlist] = useState([]);
  const namePair = {};
  const familyPair = {};
  const navigate = useNavigate();

  // const familyCounts = [];

  useEffect(() => {
    async function getLigand() {
      try {
        const results = await axios.get(`${IP}/chemblId/${id}/`);
        results.data.data.length > 0
          ? setLigand(results.data.data)
          : navigate("*");
      } catch {
        navigate("*");
      }
    }
    getLigand();

    async function getGPCRlist() {
      const results = await axios.get(`${IP}/gpcrlist/`);
      setGpcrlist(results.data.data);
    }
    getGPCRlist();
  }, [id, navigate]);
  const gpcrTemp = [];
  const nameTemp = [];
  const smileTemp = [];
  if (ligand) {
    ligand.map((c) => {
      c.molecule_name && nameTemp.push(c.molecule_name);
      gpcrTemp.push(c.uniprot_id);
      c.smiles && smileTemp.push(c.smiles);
      return null;
    });
  }
  const targetGPCR = [...new Set(gpcrTemp)];
  const name = [...new Set(nameTemp)];
  const smile = [...new Set(smileTemp)];

  if (gpcrlist) {
    gpcrlist.map((c) => {
      namePair[c.uniprot_id] = c.gene_name;
      familyPair[c.gene_name] = c.classification;
      return null;
    });
  }

  // console.log(name);
  return (
    <div className="d-flex flex-column min-vh-100">
      <NavBar />
      <br />
      <div className="mt-auto">
        <Container className="my-5">
          <Row>
            <Col className="">
              <h4 className="">{id}</h4>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col
              className="py-2 col-2 text-end fw-bold text-white"
              style={{ backgroundColor: "#263878" }}
            >
              Molecule name
            </Col>
            <Col className="py-2">
              <ul
                style={{
                  listStyleType: "none",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {name.length
                  ? name.map((c) => <li key={name}>{name}</li>)
                  : "Na"}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col
              className="py-2 col-2 text-end fw-bold text-white"
              style={{ backgroundColor: "#263878" }}
            >
              SMILES
            </Col>
            <Col className="py-2">
              <ul
                style={{
                  listStyleType: "none",
                  padding: "0px",
                  margin: "0px",
                  columnsNumber: "1",
                }}
              >
                {smile && smile.map((c) => <li key={c}>{c}</li>)}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col
              className="py-2 col-2 text-end fw-bold text-white"
              style={{ backgroundColor: "#263878" }}
            >
              Target GPCRs
            </Col>
            <Col className="py-2">
              <ul
                style={{
                  listStyleType: "none",
                  padding: "0px",
                  margin: "0px",
                  columnCount: "5",
                }}
              >
                {targetGPCR &&
                  targetGPCR.map((c) => (
                    <li key={c}>
                      <Link to={`/gpcr/${c}`}>
                        {`${namePair[c]} (Family ${familyPair[namePair[c]]})`}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
        <Container className="ms-4">
          <LigandTableChemblID namePair={namePair} />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default LigandSingle;
