import React, { useState } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import {
  Table,
  Pagination,
  Form,
  Row,
  Col,
  Container,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import {
  faFilter,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

function getExportFileBlob({ columns, data, fileType }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }
  // Other formats goes here
}

function RTable({
  columns,
  data,
  transcriptOptions,
  clinicalOptions,
  flagsOptions,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    exportData,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      initialState: { pageIndex: 0 },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );

  const [clinicalCheck, setClinicalCheck] = useState(false);
  const [flagsCheck, setFlagsCheck] = useState(false);

  function handleClinicalCheck() {
    if (clinicalCheck) {
      // const allOptions = ["", ...clinicalOptions];
      setFilter("clinical_sig", undefined);
    } else {
      setFilter("clinical_sig", clinicalOptions);
    }
    setClinicalCheck(!clinicalCheck);
  }

  function handleFlagslCheck() {
    if (flagsCheck) {
      // const allOptions = ["", ...clinicalOptions];
      setFilter("flags", undefined);
    } else {
      setFilter("flags", flagsOptions);
    }
    setFlagsCheck(!flagsCheck);
  }

  // onChange={(e) => setFilter("clinical_sig", clinicalOptions)}

  return (
    <div className="pe-0 me-0">
      <Container
        className="ps-0 my-2 py-1"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Row className="d-flex flex-wrap align-items-center justify-content-center">
          <Col className="col-2" style={{ minWidth: "10rem" }}>
            <p className="pb-0 mb-0 text-end text-nowrap">
              Advanced Filters{" "}
              <FontAwesomeIcon icon={faFilter} style={{ color: "#263878" }} />
            </p>
          </Col>
          <Col className="col-2 pe-0">
            <Form.Select
              aria-label="transcript_option"
              onChange={(e) => setFilter("transcript", e.target.value)}
            >
              <option value="">Transcript</option>
              {transcriptOptions.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="col-2 px-2">
            <Form.Select
              aria-label="vep_option"
              onChange={(e) => setFilter("VEP_annotation", e.target.value)}
            >
              <option value="">VEP annotation</option>
              <option value="3_prime_UTR_variant">3'prime UTR variant</option>
              <option value="5_prime_UTR_variant">5'prime UTR variant</option>
              <option value="coding_sequence_variant">
                coding sequence variant
              </option>
              <option value="frameshift_variant">frameshift variant</option>
              <option value="incomplete_terminal_codon_variant">
                incomplete terminal codon variant
              </option>
              <option value="inframe_deletion">inframe deletion</option>
              <option value="inframe_insertion">inframe insertion</option>
              <option value="intron_variant">intron variant</option>
              <option value="missense_variant">missense variant</option>
              <option value="non_coding_transcript_exon_variant">
                non-coding transcript exon variant
              </option>
              <option value="protein_altering_variant">
                protein altering variant
              </option>
              <option value="splice_acceptor_variant">
                splice acceptor variant
              </option>
              <option value="splice_donor_variant">splice donor variant</option>
              <option value="splice_region_variant">
                splice region variant
              </option>
              <option value="start_lost">start lost</option>
              <option value="stop_gained">stop gained</option>
              <option value="stop_lost">stop lost</option>
              <option value="stop_retained_variant">
                stop retained variant
              </option>
              <option value="synonymous_variant">synonymous variant</option>
            </Form.Select>
          </Col>

          <Col className="col-2 px-0">
            <Form.Select
              aria-label="topological_option"
              onChange={(e) => setFilter("topological", e.target.value)}
            >
              <option value="">Domain</option>
              <option value="N-term">N-term</option>
              <option value="TM1">TM1</option>
              <option value="ICL1">ICL1</option>
              <option value="TM2">TM2</option>
              <option value="ECL1">ECL1</option>
              <option value="TM3">TM3</option>
              <option value="ICL2">ICL2</option>
              <option value="TM4">TM4</option>
              <option value="ECL2">ECL2</option>
              <option value="TM5">TM5</option>
              <option value="ICL3">ICL3</option>
              <option value="TM6">TM6</option>
              <option value="ECL3">ECL3</option>
              <option value="TM7">TM7</option>
              <option value="C-term">C-term</option>
            </Form.Select>
          </Col>
          <Col className="d-flex col-1 ps-2 ms-2" style={{ minWidth: "6rem" }}>
            <Form.Check
              label="Clinical"
              name="clinical"
              type="checkbox"
              onChange={(e) => handleClinicalCheck()}
            />
          </Col>
          <Col
            className="d-flex text-nowrap col-1 mx-0 px-0"
            style={{ minWidth: "6rem" }}
          >
            <Form.Check
              label="LoF Flags"
              name="flags"
              type="checkbox"
              onChange={(e) => handleFlagslCheck()}
            />
          </Col>
          <Col>
            <Button
              size="sm"
              className="border-0"
              onClick={() => {
                exportData("csv", false);
              }}
              style={{ backgroundColor: "#263878" }}
            >
              Export
            </Button>
          </Col>
        </Row>
        {/* <Button>click</Button> */}
      </Container>

      <Table responsive striped bordered hover size="sm" {...getTableProps()}>
        <thead
          className="fw-bold text-white sticky-top"
          style={{ backgroundColor: "#263878" }}
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps(), {
                    style: {
                      maxWidth: column.maxWidth,
                    },
                  })}
                >
                  {column.render("Header")}
                  <span className="ms-3 text-nowrap">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ color: "white" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ color: "white" }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="overflow-hidden text-nowrap"
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination size="sm" className="mt-3">
        <Pagination.Item
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          First
        </Pagination.Item>
        <Pagination.Item
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Prev
        </Pagination.Item>
        <Pagination.Item onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Pagination.Item>
        <Pagination.Item
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
        </Pagination.Item>

        <span className="ms-3 d-flex align-items-center">
          Page {pageIndex + 1} of {pageOptions.length}
        </span>

        <Form.Select
          className="ms-3"
          size="sm"
          style={{ width: "110px" }}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[15, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      </Pagination>
    </div>
  );
}

export default RTable;
