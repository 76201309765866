import React from "react";
import Carousel from "react-bootstrap/Carousel";
import snakePlotExp from "../../figs/snakePlot.png";
import variationTableExp from "../../figs/variationTable.png";
import biopredictorExp from "../../figs/biopredictor.png";
import expression from "../../figs/expression.png";
import ligandExp from "../../figs/ligandTable.png";

function Carouselset(props) {
  return (
    <div>
      <Carousel variant="dark" style={{ overflow: "hidden" }}>
        <Carousel.Item>
          <img className="d-block w-100" src={snakePlotExp} alt="First slide" />
          <Carousel.Caption
            className="mb-0 pb-0 pt-3 fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            {/* <h5>Multiple functional Snake plot</h5> */}
            <p>GPCR variant snake plots.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={variationTableExp}
            alt="First slide"
          />
          <Carousel.Caption
            className="mb-0 pb-0 pt-3 fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            {/* <h5>Functional variation table</h5> */}
            <p>GPCR variant table.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={biopredictorExp}
            alt="Second slide"
          />

          <Carousel.Caption
            className="mb-0 pb-0 pt-3 fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            {/* <h5>EC50 and IC50 value predictor</h5> */}
            <p>Predictions of EC50 and IC50 for GPCRs.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={expression} alt="Third slide" />

          <Carousel.Caption
            className="mb-0 pb-0 pt-3 fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            {/* <h5>Expressions for each GPCR</h5> */}
            <p>Transcript-level expressions in human tissues.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={ligandExp} alt="First slide" />
          <Carousel.Caption
            className="mb-0 pb-0 pt-3 fw-bold text-white"
            style={{ backgroundColor: "#263878" }}
          >
            {/* <h5>Targeted ligands table</h5> */}
            <p>GPCR ligand tables.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Carouselset;
