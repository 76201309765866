import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  // faFacebookF,
  // faLinkedinIn,
  faGithubAlt,
} from "@fortawesome/free-brands-svg-icons";
import logo2 from "../../7TMkb_Icon2.png";

function Footer(props) {
  return (
    <Container fluid className="text-md-start mt-5 mb-0">
      <Row
        className="text-nowrap d-flex align-items-center"
        style={{
          backgroundColor: "#c5e9fb",
          // height: "30px",
        }}
      >
        <Col className="ms-5 d-flex justify-content-start">
          <h6 className="mt-2"> Reach us on social networks:</h6>
        </Col>
        <Col className="me-5 d-flex justify-content-end">
          <a
            href="https://twitter.com/SRouuuuu"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#263878" }}
            className="me-4"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>

          {/* <a
            href="https://www.facebook.com/xin.ma.58511276/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#263878" }}
            className="me-4"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a> */}

          {/* <a
            href="https://www.linkedin.com/in/xin-ma-59104323a"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#263878" }}
            className="me-4"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a> */}
          <a
            href="https://github.com/SchoolBaggErrr"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#263878" }}
          >
            <FontAwesomeIcon icon={faGithubAlt} />
          </a>
        </Col>
      </Row>
      <Row
        className="pt-5 pb-3 text-white"
        style={{ backgroundColor: "#263878" }}
      >
        <Col className="col-2 mx-auto" style={{ minWidth: "10rem" }}>
          {/* <h6 className=" fw-bold">7TMkb</h6> */}
          {/* <hr
            className="mb-4 mt-0 d-inline-block mx-auto"
            style={{
              width: "50px",
              backgroundColor: "#DADADA",
              height: "2px",
            }}
          /> */}
          <img
            // className="col-8 mx-auto"
            style={{ width: "3rem" }}
            src={logo2}
            alt="7TM Web Logo2"
          />
          <p className="mt-4">
            <strong className="fs-4">7TMkb</strong> is a knowledgebase for data
            mining and analysis of seven-transmembrane receptors
          </p>
        </Col>
        <Col className="col-2 mx-auto" style={{ minWidth: "10rem" }}>
          <h6 className="text-uppercase fw-bold">Quick links</h6>
          <hr
            className="mb-4 mt-0 d-inline-block mx-auto"
            style={{
              width: "50px",
              backgroundColor: "#DADADA",
              height: "2px",
            }}
          />
          <p>
            <Link to="/" className="text-decoration-none footLink">
              Homepage
            </Link>
          </p>
          <p>
            <Link to="/gpcr/" className="text-decoration-none footLink">
              GPCRs
            </Link>
          </p>

          <p>
            <Link to="/biopred/" className="text-decoration-none footLink">
              Bioactivity predictor
            </Link>
          </p>
          <p>
            <Link to="/help#about" className="text-decoration-none footLink">
              About 7TM
            </Link>
          </p>
        </Col>
        <Col className="col-2 mx-auto" style={{ minWidth: "10rem" }}>
          <h6 className="text-uppercase fw-bold ">Documentation</h6>
          <hr
            className="mb-4 mt-0 d-inline-block mx-auto"
            style={{
              width: "50px",
              backgroundColor: "#DADADA",
              height: "2px",
            }}
          />
          <p>
            <HashLink
              to="/help#stats"
              className="text-decoration-none footLink "
            >
              Data Stats
            </HashLink>
          </p>
          <p>
            <HashLink
              to="/help#search"
              className="text-decoration-none footLink"
            >
              Searching functions
            </HashLink>
          </p>
          <p>
            <HashLink to="/help#data" className="text-decoration-none footLink">
              Data resources
            </HashLink>
          </p>

          <p>
            <HashLink
              to="/help#predictor"
              className="text-decoration-none footLink"
            >
              Bioactivity predictor
            </HashLink>
          </p>
        </Col>
        <Col className="col-2 mx-auto" style={{ minWidth: "15rem" }}>
          <h6 className="text-uppercase fw-bold">Contact</h6>
          <hr
            className="mb-4 mt-0 d-inline-block mx-auto"
            style={{
              width: "50px",
              backgroundColor: "#DADADA",
              height: "2px",
            }}
          />

          <p>
            <FontAwesomeIcon icon={faEnvelope} /> 7TMkb@biosustain.dtu.dk
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
