import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  FormControl,
  InputGroup,
  Form,
  Stack,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

function SearchBar({ drug, gpcr, ligand }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [options, setOptions] = useState("gpcr");
  const [placeHolder, setPlaceHolder] = useState("Uniprot ID or Gene name");

  const placeHolder1 = "Uniprot ID or Gene name (e.g. HTR4)";
  const placeHolder2 = "Drug name";
  const placeHolder3 = "Chembl ID or Molecule Name";

  useEffect(() => {
    if (options === "gpcr") {
      setPlaceHolder(placeHolder1);
      setData([...gpcr]);
    } else if (options === "drug") {
      setPlaceHolder(placeHolder2);
      setData([...drug]);
    } else if (options === "ligand") {
      setPlaceHolder(placeHolder3);
      setData([...ligand]);
    }
  }, [gpcr, drug, ligand, options]);

  const handleOptions = (e) => {
    setOptions(e.target.value.toLowerCase());
    setWordEntered("");
    setFilteredData([]);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    const newFilter = data.filter((c) => {
      if (c.uniprot_id) {
        return (
          c.uniprot_id.toLowerCase().includes(searchWord.toLowerCase()) ||
          c.gene_name.toLowerCase().includes(searchWord.toLowerCase())
        );
      } else if (c.drug_id) {
        return c.drug_name.toLowerCase().includes(searchWord.toLowerCase());
      } else if (c.chembl_id) {
        if (searchWord.length >= 5) {
          return c.molecule_name
            .toLowerCase()
            .includes(searchWord.toLowerCase());
        }
      }
      return null;
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const handleDirect = (e) => {
    e.preventDefault();
    if (options === "ligand") {
      if (filteredData.length === 1) {
        navigate(`/chembl/${filteredData[0].chembl_id}`);
      } else if (
        filteredData.length === 0 &&
        wordEntered.toLowerCase().startsWith("chembl")
      ) {
        navigate(`/chembl/${wordEntered}`);
      }
    } else {
      if (filteredData.length === 1) {
        if (options === "gpcr") {
          navigate(`/gpcr/${filteredData[0].uniprot_id}`);
        } else if (options === "drug") {
          navigate(`/drug/${filteredData[0].drug_id}`);
        }
      } else {
      }
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };
  return (
    <Container>
      <Row>
        <Form className="px-0 mx-0" onSubmit={handleDirect}>
          <Stack
            direction="horizontal"
            gap={0}
            className="rounded"
            style={{
              backgroundColor: "#263878",
              borderStyle: "solid",
              borderColor: "#263878",
            }}
          >
            <div className="col-3 mx-0 px-0">
              <Form.Select
                onChange={handleOptions}
                className="text-white text-center fw-bold"
                style={{
                  backgroundColor: "#263878",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: "none",
                  height: "50px",
                }}
              >
                <option value="gpcr">GPCR</option>
                <option value="drug">Drug</option>
                <option value="ligand">Ligand</option>
              </Form.Select>
            </div>
            <div className="col-9 mx-0 px-0">
              <InputGroup
                className="px-0 mx-0"
                style={{
                  height: "50px",
                }}
              >
                <FormControl
                  className="ps-3"
                  type="text"
                  value={wordEntered}
                  placeholder={placeHolder}
                  onChange={handleFilter}
                  style={{
                    border: "none",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    color: "#263878",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={clearInput}
                >
                  {filteredData.length === 0 && wordEntered === "" ? (
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      onClick={clearInput}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faXmark}
                      onClick={clearInput}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Stack>
        </Form>
      </Row>
      <Row>
        <Stack className="px-0 mx-0" direction="horizontal" gap={0}>
          <div className="col-3 mx-0 px-0"></div>
          <div className="col-9 mx-0 px-0" style={{ position: "relative" }}>
            {filteredData.length !== 0 ? (
              <Row
                className="d-flex align-content-start border border-top-0 mx-0 px-0 mb-2 pb-2"
                style={{
                  height: "auto",
                  width: "100%",
                  backgroundColor: "white",
                  maxHeight: "250px",
                  overflow: "hidden",
                  overflowY: "auto",
                  position: "absolute",
                  zIndex: "1000",
                }}
              >
                {filteredData.map((c) => {
                  if (c.uniprot_id) {
                    return (
                      <Link
                        to={`/gpcr/${c.uniprot_id}`}
                        className="text-decoration-none text-reset mt-2"
                        target="_blank"
                        key={c.uniprot_id}
                      >
                        <ListGroup
                          horizontal
                          className="searchLink d-flex justify-content-center"
                        >
                          <ListGroup.Item className="col-6 text-center">
                            {c.uniprot_id}:
                          </ListGroup.Item>
                          <ListGroup.Item className="col-6 text-center">
                            {c.gene_name}
                          </ListGroup.Item>
                        </ListGroup>
                      </Link>
                    );
                  } else if (c.drug_name) {
                    return (
                      <Link
                        to={`/drug/${c.drug_id}`}
                        className="searchLink text-decoration-none text-reset 
                        mt-2 col-10"
                        target="_blank"
                        key={c.drug_name}
                      >
                        {c.drug_name}
                      </Link>
                    );
                  } else if (c.chembl_id) {
                    return (
                      <Link
                        to={`/chembl/${c.chembl_id}`}
                        className="searchLink text-decoration-none text-reset 
                      mt-2 col-10"
                        target="_blank"
                        key={c.chembl_id}
                      >
                        {c.molecule_name}
                      </Link>
                    );
                  }
                  return null;
                })}
              </Row>
            ) : (
              options !== "ligand" &&
              wordEntered !== "" && (
                <Row style={{ position: "absolute" }}>
                  <small className="ms-3 mt-2">
                    No results found for <strong>{wordEntered}</strong>
                  </small>
                </Row>
              )
            )}
          </div>
        </Stack>
      </Row>
    </Container>
  );
}

export default SearchBar;
