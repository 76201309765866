import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Stats(props) {
  return (
    <Container className="">
      <h6 className="ms-0 ps-0 mb-3 fw-bold">Statistics</h6>
      <Row
        className="justify-content-start"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Col xs={8}>Non-Olfactory GPCRs:</Col>
        <Col className="fw-bold text-start" style={{ color: "#263878" }}>
          439
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col xs={8}>Olfactory GPCRs:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          427
        </Col>
      </Row>
      <Row
        className="justify-content-start"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Col xs={8}>Taste GPCRs:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          30
        </Col>
      </Row>
      <Row className="">
        <Col xs={8}>GPCR ligand targets:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          384
        </Col>
      </Row>
      <Row
        className="justify-content-start"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Col xs={8}>GPCR drug targets:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          159
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col xs={8}>Ligands:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          392960
        </Col>
      </Row>
      <Row
        className="justify-content-start"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Col xs={8}>Drugs:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          621
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col xs={8}>Isoforms:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          2812
        </Col>
      </Row>
      <Row
        className="justify-content-start"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Col xs={8}>GPCR variants:</Col>
        <Col className="fw-bold" style={{ color: "#263878" }}>
          314451
        </Col>
      </Row>
    </Container>
  );
}

export default Stats;
