import React from "react";
import { Container, Figure } from "react-bootstrap";
import NavBar from "./common/NavBar";
import Footer from "./common/Footer";
import png404 from "../404.png";

function NotFoundPage(props) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <NavBar />
      <br />
      <br />
      <br />
      <Container className="my-auto">
        <Figure className="d-flex justify-content-center">
          <Figure.Image width={400} height={400} src={png404} />
        </Figure>
      </Container>

      <Footer />
    </div>
  );
}

export default NotFoundPage;
