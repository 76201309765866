import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { Container } from "react-bootstrap";
import RTable from "./RTable";
import SNPBarplot from "./figure/SNPBarplot";
import SNPDomain from "./figure/SNPDomain";
import PopulationBar from "./figure/PopulationBar";
import Snakeplot from "./figure/Snakeplot";
import IPaddress from "../../services/IPaddress";

const IP = IPaddress();

function VarTable({ canonical, canonical_seq, snake_domain }) {
  const { id } = useParams();
  const [variation, setVariation] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [clinicalSig, setClinicalSig] = useState("");
  const [flags, setFlags] = useState("");

  useEffect(() => {
    async function getVariation() {
      const results = await axios.get(`${IP}/variation/${id}/`);
      setVariation(results.data.data);
      setTranscript([
        ...new Set([...results.data.data.map((c) => c.transcript)].sort()),
      ]);
      setClinicalSig([
        ...new Set([...results.data.data.map((c) => c.clinical_sig)]),
      ]);
      setFlags([...new Set([...results.data.data.map((c) => c.flags)])]);
    }
    getVariation();
  }, [id]);

  function multipleFilter(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
          filterValue.includes(String(row.original[columnIds]))
        );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Chr",
        accessor: "chr", // accessor is the "key" in the data
        disableSortBy: true,
      },
      {
        Header: "Pos",
        accessor: "pos",
        // disableSortBy: true,
      },
      {
        Header: "rsIDs",
        accessor: "rsIDs",
        disableSortBy: true,
      },
      {
        Header: "Ref",
        accessor: "ref",
        maxWidth: 100,
        style: { whiteSpace: "unset" },
        disableSortBy: true,
      },
      {
        Header: "Alt",
        accessor: "alt",
        maxWidth: 100,
        style: { whiteSpace: "unset" },
        disableSortBy: true,
      },
      {
        Header: "Transcript",
        accessor: "transcript",
        disableSortBy: true,
      },
      {
        Header: "HGVS",
        accessor: "HGVS_consequence",
        disableSortBy: true,
      },
      {
        Header: "VEP annotation",
        accessor: "VEP_annotation",
        disableSortBy: true,
        minWidth: 150,
      },
      {
        Header: "Domain",
        accessor: "topological",
        disableSortBy: true,
      },
      {
        Header: "Clinical",
        accessor: "clinical_sig",
        disableSortBy: true,
        filter: multipleFilter,
      },
      {
        Header: "ClinVarID",
        accessor: "clinaVarID",
        disableSortBy: true,
      },
      {
        Header: "Flags",
        accessor: "flags",
        disableSortBy: true,
        filter: multipleFilter,
      },
      {
        Header: "A.F.",
        accessor: "allele_frequency",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "AFR",
        accessor: "AFR",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "AMI",
        accessor: "AMI",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "AMR",
        accessor: "AMR",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "ASJ",
        accessor: "ASJ",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "EAS",
        accessor: "EAS",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "FIN",
        accessor: "FIN",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "NFE",
        accessor: "NFE",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "MID",
        accessor: "MID",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "SAS",
        accessor: "SAS",
        minWidth: 150,
        sortDescFirst: true,
      },
      {
        Header: "OTH",
        accessor: "OTH",
        minWidth: 150,
        sortDescFirst: true,
      },
    ],
    []
  );

  return (
    <div style={{ width: "95vw" }}>
      <RTable
        columns={columns}
        data={variation}
        transcriptOptions={transcript}
        clinicalOptions={clinicalSig}
        flagsOptions={flags}
      ></RTable>
      <p className="col-8 my-3">
        <small>
          <mark style={{ backgroundColor: "#c5e9fb" }}>
            A.F.: Allele frequency, AFR: African/African American, AMI: Amish,
            AMR: Latino/Admixed American, ASJ: Ashkenazi Jewish, EAS: East
            Asian, FIN: Finnish, NFE: European(non-Finnish), MID: Middle
            Eastern, SAS: South Asian, OTH: Other.
          </mark>
        </small>
      </p>
      <br />
      <br />
      <h6 className="ms-4 mb-3">3.2 Variant Statistics</h6>
      <SNPBarplot variation={variation} transcript={transcript} />
      <br />
      <br />
      <SNPDomain
        variation={variation}
        canonical={canonical}
        snake_domain={snake_domain}
      />
      <br />
      <br />
      <PopulationBar variation={variation} canonical={canonical} />
      <br />
      <br />
      <br />
      <br />
      <Snakeplot
        canonical={canonical}
        canonical_seq={canonical_seq}
        snake_domain={snake_domain}
        labels={canonical_seq ? canonical_seq.split("") : ""}
        variation={variation.filter(
          (c) => c.HGVS_consequence && c.HGVS_consequence.startsWith("p.")
        )}
      />
    </div>
  );
}

export default VarTable;
