function getLength(list) {
  return list[1] - list[0] + 1;
}

function getNterm(list, length) {
  let pos = [];
  // if (length > 70) {
  // }
  if (length <= 12) {
    for (let i = 0; i < 12; i++) {
      let point_pos = { x: 2, y: 6.6 + i * 0.3 };
      pos.push(point_pos);
    }
  } else {
    for (let i = 0; i < 12; i++) {
      let point_pos = { x: 2, y: 6.6 + i * 0.3 };
      pos.push(point_pos);
    }
    for (let i = 12; i < length; i++) {
      let point_pos = { x: 2 + 0.2 * (i - 11), y: 9.9 };
      pos.push(point_pos);
    }
  }
  return pos.reverse();
}

function getTM(list, length, num) {
  const pos = [];
  const x_mid = 2 * num;
  let rowNum = 0;
  if (length % 5 === 0) {
    rowNum = length / 5;
    for (let i = 0; i < rowNum; i++) {
      pos.push({ x: x_mid - 0.4, y: 6 - i * 0.4 });
      pos.push({ x: x_mid - 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.4, y: 6 - i * 0.4 });
    }
  } else {
    rowNum = Math.ceil(length / 5);
    for (let i = 0; i < rowNum - 1; i++) {
      pos.push({ x: x_mid - 0.4, y: 6 - i * 0.4 });
      pos.push({ x: x_mid - 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.4, y: 6 - i * 0.4 });
    }
    const mod = length % 5;
    for (let i = 0; i < mod; i++) {
      pos.push({ x: x_mid - 0.4 + 0.2 * i, y: 6 - (rowNum - 1) * 0.4 });
    }
  }
  return [pos, 6 - (rowNum - 1) * 0.4];
}

function getTMReverse(list, length, num) {
  const pos = [];
  const x_mid = 2 * num;
  let rowNum = 0;
  if (length % 5 === 0) {
    rowNum = length / 5;
    for (let i = rowNum - 1; i > 0; i--) {
      pos.push({ x: x_mid - 0.4, y: 6 - i * 0.4 });
      pos.push({ x: x_mid - 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.4, y: 6 - i * 0.4 });
    }
  } else {
    rowNum = Math.ceil(length / 5);
    for (let i = rowNum - 1; i > 0; i--) {
      pos.push({ x: x_mid - 0.4, y: 6 - i * 0.4 });
      pos.push({ x: x_mid - 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.2, y: 6 - i * 0.4 });
      pos.push({ x: x_mid + 0.4, y: 6 - i * 0.4 });
    }
    const mod = length % 5;
    for (let i = 0; i < mod; i++) {
      pos.push({ x: x_mid - 0.4 + 0.2 * i, y: 6 });
    }
  }
  return [pos, 6 - (rowNum - 1) * 0.4];
}

function getECL(list, length, num) {
  const pos = [];
  const xPos = 4 + (num - 1) * 4;
  if (length < 30) {
    if (length > 10) {
      const leftPart = Math.floor((length - 9) / 2);
      const rightPart = Math.ceil((length - 9) / 2);
      const midPart = 8;
      const sideEnd = 6.6 + 0.3 * (rightPart - 1);
      const leftstart = leftPart === rightPart ? 6.6 : 6.9;
      for (let i = 0; i < leftPart; i++) {
        pos.push({ x: xPos, y: leftstart + i * 0.3 });
      }
      for (let i = leftPart; i < leftPart + midPart + 1; i++) {
        pos.push({ x: xPos + 0.2 * (i - leftPart + 1), y: sideEnd });
      }
      for (let i = leftPart + midPart + 1; i < length; i++) {
        pos.push({
          x: xPos + 2,
          y: sideEnd - 0.3 * (i - leftPart - midPart - 1),
        });
      }
    } else {
      for (let i = 0; i < length; i++) {
        pos.push({ x: xPos + i * 0.2, y: 6.6 });
      }
    }
  } else {
    for (let i = 0; i < 10; i++) {
      pos.push({ x: xPos, y: 6.6 + 0.3 * i });
    }
    const newLength = length - 10;
    for (let i = 1; i <= Math.floor(newLength / 8); i++) {
      for (let j = 1; j <= 8; j++) {
        pos.push({ x: xPos + 0.2 * i, y: 6.6 + 0.3 * j });
      }
    }
    if (newLength % 8 !== 0) {
      for (let i = 1; i <= newLength % 8; i++) {
        pos.push({
          x: xPos + 0.2 * Math.ceil(newLength / 8),
          y: 6.6 + 0.3 * i,
        });
      }
    }
  }
  return pos;
}

function getICL(list, length, num, TMLy, TMRy) {
  const pos = [];
  const xPos = 2 + (num - 1) * 4;
  const yStart = Math.min(TMLy, TMRy) - 0.6;
  if (length < 30) {
    if (length > 10) {
      const leftPart = Math.floor((length - 9) / 2);
      const rightPart = Math.ceil((length - 9) / 2);
      const midPart = 8;
      const sideEnd = yStart - 0.3 * (rightPart - 1);
      for (let i = 0; i < leftPart; i++) {
        pos.push({ x: xPos, y: yStart - i * 0.3 });
      }
      for (let i = leftPart; i < leftPart + midPart + 1; i++) {
        pos.push({ x: xPos + 0.2 * (i - leftPart + 1), y: sideEnd });
      }
      for (let i = leftPart + midPart + 1; i < length; i++) {
        pos.push({
          x: xPos + 2,
          y: sideEnd + 0.3 * (i - leftPart - midPart - 1),
        });
      }
    }
  } else {
    for (let i = 0; i < 10; i++) {
      pos.push({ x: xPos, y: yStart - 0.3 * i });
    }
    const newLength = length - 10;
    for (let i = 1; i <= Math.floor(newLength / 8); i++) {
      for (let j = 1; j <= 8; j++) {
        pos.push({ x: xPos + 0.2 * i, y: yStart - 0.3 * j });
      }
    }
    if (newLength % 8 !== 0) {
      for (let i = 1; i <= newLength % 8; i++) {
        pos.push({
          x: xPos + 0.2 * Math.ceil(newLength / 8),
          y: yStart - 0.3 * i,
        });
      }
    }
  }
  return pos;
}

function getCterm(list, length, TM7y) {
  const pos = [];
  const xStart = 14;
  const yStart = TM7y - 0.6;
  if (length <= 13) {
    for (let i = 0; i < 13; i++) {
      let point_pos = { x: xStart, y: yStart - i * 0.3 };
      pos.push(point_pos);
    }
  } else {
    for (let i = 0; i < 13; i++) {
      let point_pos = { x: xStart, y: yStart - i * 0.3 };
      pos.push(point_pos);
    }
    for (let i = 13; i < length; i++) {
      let point_pos = { x: xStart - 0.2 * (i - 12), y: yStart - 12 * 0.3 };
      pos.push(point_pos);
    }
  }
  return pos;
}

export default function CalSnakePos(snake_domain) {
  if (snake_domain) {
    const domainList = snake_domain.split(",");
    if (domainList.length === 30) {
      const nterm = [domainList[0], domainList[1]];
      const ntermLength = getLength(nterm);
      const TM1 = [domainList[2], domainList[3]];
      const TM1Length = getLength(TM1);
      const ICL1 = [domainList[4], domainList[5]];
      const ICL1Length = getLength(ICL1);
      const TM2 = [domainList[6], domainList[7]];
      const TM2Length = getLength(TM2);
      const ECL1 = [domainList[8], domainList[9]];
      const ECL1Length = getLength(ECL1);
      const TM3 = [domainList[10], domainList[11]];
      const TM3Length = getLength(TM3);
      const ICL2 = [domainList[12], domainList[13]];
      const ICL2Length = getLength(ICL2);
      const TM4 = [domainList[14], domainList[15]];
      const TM4Length = getLength(TM4);
      const ECL2 = [domainList[16], domainList[17]];
      const ECL2Length = getLength(ECL2);
      const TM5 = [domainList[18], domainList[19]];
      const TM5Length = getLength(TM5);
      const ICL3 = [domainList[20], domainList[21]];
      const ICL3Length = getLength(ICL3);
      const TM6 = [domainList[22], domainList[23]];
      const TM6Length = getLength(TM6);
      const ECL3 = [domainList[24], domainList[25]];
      const ECL3Length = getLength(ECL3);
      const TM7 = [domainList[26], domainList[27]];
      const TM7Length = getLength(TM7);
      const cterm = [domainList[28], domainList[29]];
      const ctermLength = getLength(cterm);

      const ntermData = getNterm(nterm, ntermLength);
      const TM1Data = getTM(TM1, TM1Length, 1);
      const TM2Data = getTMReverse(TM2, TM2Length, 2);
      const ICL1Data = getICL(ICL1, ICL1Length, 1, TM1Data[1], TM2Data[1]);
      const ECL1Data = getECL(ECL1, ECL1Length, 1);
      const TM3Data = getTM(TM3, TM3Length, 3);
      const TM4Data = getTMReverse(TM4, TM4Length, 4);
      const ICL2Data = getICL(ICL2, ICL2Length, 2, TM3Data[1], TM4Data[1]);
      const ECL2Data = getECL(ECL2, ECL2Length, 2);
      const TM5Data = getTM(TM5, TM5Length, 5);
      const TM6Data = getTMReverse(TM6, TM6Length, 6);
      const ICL3Data = getICL(ICL3, ICL3Length, 3, TM5Data[1], TM6Data[1]);
      const ECL3Data = getECL(ECL3, ECL3Length, 3);
      const TM7Data = getTM(TM7, TM7Length, 7);
      const ctermData = getCterm(cterm, ctermLength, TM7Data[1]);

      const data = [
        ...ntermData,
        ...TM1Data[0],
        ...ICL1Data,
        ...TM2Data[0],
        ...ECL1Data,
        ...TM3Data[0],
        ...ICL2Data,
        ...TM4Data[0],
        ...ECL2Data,
        ...TM5Data[0],
        ...ICL3Data,
        ...TM6Data[0],
        ...ECL3Data,
        ...TM7Data[0],
        ...ctermData,
      ];
      return data;
    } else {
      return [];
    }
  } else {
    return [];
  }
}
