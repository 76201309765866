import React from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import {
  Table,
  Pagination,
  Form,
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

function getExportFileBlob({ columns, data, fileType }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }
  // Other formats goes here
}

function RTableLigand({
  columns,
  data,
  typeOptions,
  organismOptions,
  tissueOptions,
  cellOptions,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    exportData,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      initialState: { pageIndex: 0 },
      globalFilter: searh_two_columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData
  );

  function searh_two_columns(rows, columnIds, filterValue) {
    return rows.filter(
      (row) =>
        (row.values["chembl_id"] &&
          row.values["chembl_id"].toLowerCase().includes(filterValue)) ||
        (row.values["molecule_name"] &&
          row.values["molecule_name"].toLowerCase().includes(filterValue))
    );
  }

  return (
    <Container fluid>
      <Container
        className="ps-0 my-2 py-1"
        style={{ backgroundColor: "#c5e9fb" }}
      >
        <Row className="d-flex flex-wrap align-items-center justify-content-center">
          <Col className="col-2" style={{ minWidth: "10rem" }}>
            <p className="pb-0 mb-0 text-end text-nowrap">
              Advanced Filters{" "}
              <FontAwesomeIcon icon={faFilter} style={{ color: "#263878" }} />
            </p>
          </Col>

          <Col className="col-2 pe-0">
            <InputGroup>
              <FormControl
                placeholder="Chembl id or Name"
                aria-label="chembl_id"
                onChange={(e) => setGlobalFilter(e.target.value.toLowerCase())}
              />
            </InputGroup>
          </Col>

          <Col className="col-1 pe-0">
            <Form.Select
              aria-label="type_option"
              onChange={(e) => setFilter("standard_type", e.target.value)}
            >
              <option value="">Type</option>
              {typeOptions.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="col-2 px-2">
            <Form.Select
              aria-label="organism_option"
              onChange={(e) => setFilter("assay_organism", e.target.value)}
            >
              <option value="">Assay organism</option>
              {organismOptions.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="col-2 px-2">
            <Form.Select
              aria-label="tissue_option"
              onChange={(e) => setFilter("assay_tissue", e.target.value)}
            >
              <option value="">Assay tissue</option>
              {tissueOptions.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col className="col-2 px-2">
            <Form.Select
              aria-label="cell_option"
              onChange={(e) => setFilter("assay_cell", e.target.value)}
            >
              <option value="">Assay cell</option>
              {cellOptions.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Button
              size="sm"
              className="border-0"
              onClick={() => {
                exportData("csv", false);
              }}
              style={{ backgroundColor: "#263878" }}
            >
              Export
            </Button>
          </Col>
        </Row>
      </Container>
      <Table responsive striped bordered hover size="sm" {...getTableProps()}>
        <thead
          className="fw-bold text-white sticky-top"
          style={{ backgroundColor: "#263878" }}
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps(), {})}
                >
                  {column.render("Header")}
                  <span className="ms-3 text-nowrap">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ color: "white" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ color: "white" }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // className="text-wrap"
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination size="sm" className="mt-3">
        <Pagination.Item
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          First
        </Pagination.Item>
        <Pagination.Item
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Prev
        </Pagination.Item>
        <Pagination.Item onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Pagination.Item>
        <Pagination.Item
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
        </Pagination.Item>

        <span className="ms-3 d-flex align-items-center">
          Page {pageIndex + 1} of {pageOptions.length}
        </span>

        <Form.Select
          className="ms-3"
          size="sm"
          style={{ width: "110px" }}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[15, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      </Pagination>
    </Container>
  );
}

export default RTableLigand;
