import React, { useEffect, useState } from "react";
import axios from "axios";
import { HeatMapGrid } from "react-grid-heatmap";
import IPaddress from "../../../services/IPaddress";

const IP = IPaddress();

function Expression({ geneID }) {
  const [exp, setExp] = useState([]);

  useEffect(() => {
    if (geneID !== undefined) {
      async function getExp() {
        const results = await axios.get(`${IP}/expression/${geneID}`);
        setExp(results.data.data);
      }
      getExp();
    }
  }, [geneID]);
  const yLabels = [];
  exp.map((c) => {
    yLabels.push(c.transcript_id);
    return null;
  });
  //  number of xlabels: 54
  const xLabels = [
    "Adipose - Subcutaneous",
    "Adipose - Visceral (Omentum)",
    "Adrenal Gland",
    "Artery - Aorta",
    "Artery - Coronary",
    "Artery - Tibial",
    "Bladder",
    "Brain - Amygdala",
    "Brain - Anterior cingulate cortex (BA24)",
    "Brain - Caudate (basal ganglia)",
    "Brain - Cerebellar Hemisphere",
    "Brain - Cerebellum",
    "Brain - Cortex",
    "Brain - Frontal Cortex (BA9)",
    "Brain - Hippocampus",
    "Brain - Hypothalamus",
    "Brain - Nucleus accumbens (basal ganglia)",
    "Brain - Putamen (basal ganglia)",
    "Brain - Spinal cord (cervical c-1)",
    "Brain - Substantia nigra",
    "Breast - Mammary Tissue",
    "Cells - Cultured fibroblasts",
    "Cells - EBV-transformed lymphocytes",
    "Cervix - Ectocervix",
    "Cervix - Endocervix",
    "Colon - Sigmoid",
    "Colon - Transverse",
    "Esophagus - Gastroesophageal Junction",
    "Esophagus - Mucosa",
    "Esophagus - Muscularis",
    "Fallopian Tube",
    "Heart - Atrial Appendage",
    "Heart - Left Ventricle",
    "Kidney - Cortex",
    "Kidney - Medulla",
    "Liver",
    "Lung",
    "Minor Salivary Gland",
    "Muscle - Skeletal",
    "Nerve - Tibial",
    "Ovary",
    "Pancreas",
    "Pituitary",
    "Prostate",
    "Skin - Not Sun Exposed (Suprapubic)",
    "Skin - Sun Exposed (Lower leg)",
    "Small Intestine - Terminal Ileum",
    "Spleen",
    "Stomach",
    "Testis",
    "Thyroid",
    "Uterus",
    "Vagina",
    "Whole Blood",
  ];

  const data = [];
  exp.map((c) => {
    data.push(c.expression.split(","));
    return null;
  });

  return (
    <div className="ps-0 ms-0">
      {yLabels.length ? (
        <div className="mb-3">
          <small>
            <mark>Hover the cell to check the detaied TPM values.</mark>
          </small>
        </div>
      ) : (
        ""
      )}
      {yLabels.length ? (
        <HeatMapGrid
          data={data}
          xLabels={xLabels}
          yLabels={yLabels}
          // Reder cell with tooltip
          cellRender={(x, y, value) => (
            <div
              style={{ color: "transparent" }}
              title={`TPM: ${value}\n${yLabels[x]}\n${xLabels[y]}`}
            >
              {value}
            </div>
          )}
          xLabelsStyle={(index) => ({
            // color: index % 2 ? "transparent" : "#777",
            color: "black",
            fontSize: ".8rem",
            whiteSpace: "nowrap",
            // minHeight: "30rem",
            writingMode: "vertical-rl",
            textAlign: "left",
          })}
          yLabelsStyle={() => ({
            fontSize: "0.8rem",
            textTransform: "uppercase",
            color: "black",
          })}
          cellStyle={(_x, _y, ratio) => ({
            background: `rgb(12, 160, 44, ${ratio})`,
            fontSize: ".5rem",
            color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
            borderColor: "#808080",
          })}
          cellHeight="1.35rem"
          xLabelsPos="bottom"
          yLabelsPos="left"
          square
        />
      ) : (
        <div className="fw-bold ps-2">Expression data not applicable</div>
      )}
    </div>
  );
}

export default Expression;
